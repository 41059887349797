import {
	useMutation,
	type UseMutationResult,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { AxiosResponse } from "axios";

import {
	type IFormDefinition,
	isStepInstanceFormAnswer,
	type IStepInstanceFormAnswer,
} from "@metronome/types/FormDefinition";

import { stepInstanceKeys } from "./useStepInstance";

import { apiDelete, apiGet, apiPost, apiPut } from "./api";

const formKeys = {
	all: (workspaceId: string, stepInstanceId?: string) =>
		[workspaceId, "step-instances", stepInstanceId, "forms"] as const,
};

interface BaseParams {
	workspaceId: string;
	stepInstanceId: string;
}

export default function useForms(
	stepInstanceId?: string,
): UseQueryResult<IFormDefinition[] | undefined, Error> {
	const workspaceId = useWorkspaceId();

	return useQuery({
		queryKey: formKeys.all(workspaceId, stepInstanceId),
		queryFn: () =>
			apiGet<IFormDefinition[]>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/forms`,
			),
		enabled: !!stepInstanceId,
	});
}

interface UpdateFormAnswerArgs extends BaseParams {
	formSpecId: string;
	answerToBeUpdated: IStepInstanceFormAnswer;
}

const updateFormAnswer = async ({
	workspaceId,
	stepInstanceId,
	formSpecId,
	answerToBeUpdated,
}: UpdateFormAnswerArgs): Promise<IStepInstanceFormAnswer> => {
	const res = await apiPut(
		`ws/${workspaceId}/step-instances/${stepInstanceId}/forms/${formSpecId}/answers/${answerToBeUpdated.id}`,
		{
			data: answerToBeUpdated.data,
		},
	);
	if (isStepInstanceFormAnswer(res.data)) {
		return res.data;
	}
	return Promise.reject(res.data);
};

interface DeleteFormAnswerArgs extends BaseParams {
	formSpecId: string;
	id: string;
}
const deleteFormAnswer = async ({
	workspaceId,
	stepInstanceId,
	formSpecId,
	id,
}: DeleteFormAnswerArgs): Promise<void> => {
	await apiDelete(
		`ws/${workspaceId}/step-instances/${stepInstanceId}/forms/${formSpecId}/answers/${id}`,
	);
};

export function useAddFormAnswer(
	stepInstanceId: string,
	formSpecId: string,
): UseMutationResult<
	AxiosResponse<IStepInstanceFormAnswer>,
	Error,
	{ [key: string]: object }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (newData: { [key: string]: object }) =>
			apiPost(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/forms/${formSpecId}/answers`,
				{
					data: newData,
				},
			),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: formKeys.all(workspaceId, stepInstanceId),
			});
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.requirements(workspaceId, stepInstanceId),
			});
		},
	});
}

export function useUpdateFormAnswer(
	stepInstanceId: string,
	formSpecId: string,
): UseMutationResult<IStepInstanceFormAnswer, Error, IStepInstanceFormAnswer> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (answerToBeUpdated: IStepInstanceFormAnswer) =>
			updateFormAnswer({
				workspaceId,
				stepInstanceId,
				formSpecId,
				answerToBeUpdated,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: formKeys.all(workspaceId, stepInstanceId),
			});
		},
	});
}

export function useDeleteFormAnswer(
	stepInstanceId: string,
	formSpecId: string,
): UseMutationResult<void, Error, string> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id: string) =>
			deleteFormAnswer({
				workspaceId,
				stepInstanceId,
				formSpecId,
				id,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: formKeys.all(workspaceId, stepInstanceId),
			});
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.requirements(workspaceId, stepInstanceId),
			});
		},
	});
}
