/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as IndexImport } from './routes/index'
import { Route as ErrorErrorImport } from './routes/error/_error'
import { Route as ErrorForbiddenWorkspaceImport } from './routes/_error/forbidden-workspace'
import { Route as WorkspaceIdWorkloadImport } from './routes/$workspaceId/workload'
import { Route as WorkspaceIdStreamsImport } from './routes/$workspaceId/streams'
import { Route as WorkspaceIdActivitiesImport } from './routes/$workspaceId/activities'
import { Route as ErrorErrorNoWorkspaceImport } from './routes/error/_error.no-workspace'
import { Route as ErrorErrorForbiddenWorkspaceImport } from './routes/error/_error.forbidden-workspace'
import { Route as WorkspaceIdPartyPartyIdImport } from './routes/$workspaceId/party/$partyId'
import { Route as WorkspaceIdStreamStreamIdRoutineImport } from './routes/$workspaceId/stream/$streamId/routine'
import { Route as WorkspaceIdStreamStreamIdPortfolioImport } from './routes/$workspaceId/stream/$streamId/portfolio'
import { Route as WorkspaceIdStreamStreamIdClusteredImport } from './routes/$workspaceId/stream/$streamId/clustered'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdRouteImport } from './routes/$workspaceId/stream/$streamId/process/$processId/route'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdTimelineImport } from './routes/$workspaceId/stream/$streamId/process/$processId/timeline'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdStepsImport } from './routes/$workspaceId/stream/$streamId/process/$processId/steps'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdNotesImport } from './routes/$workspaceId/stream/$streamId/process/$processId/notes'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdHistoryImport } from './routes/$workspaceId/stream/$streamId/process/$processId/history'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsImport } from './routes/$workspaceId/stream/$streamId/process/$processId/attachments'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexImport } from './routes/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/index'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdImport } from './routes/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
import { Route as WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdImport } from './routes/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'

// Create Virtual Routes

const ErrorImport = createFileRoute('/error')()
const WorkspaceIdIndexLazyImport = createFileRoute('/$workspaceId/')()

// Create/Update Routes

const ErrorRoute = ErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/onboarding.lazy').then((d) => d.Route))

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdIndexLazyRoute = WorkspaceIdIndexLazyImport.update({
  id: '/$workspaceId/',
  path: '/$workspaceId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/index.lazy').then((d) => d.Route),
)

const ErrorErrorRoute = ErrorErrorImport.update({
  id: '/_error',
  getParentRoute: () => ErrorRoute,
} as any)

const ErrorForbiddenWorkspaceRoute = ErrorForbiddenWorkspaceImport.update({
  id: '/_error/forbidden-workspace',
  path: '/forbidden-workspace',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdWorkloadRoute = WorkspaceIdWorkloadImport.update({
  id: '/$workspaceId/workload',
  path: '/$workspaceId/workload',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/workload.lazy').then((d) => d.Route),
)

const WorkspaceIdStreamsRoute = WorkspaceIdStreamsImport.update({
  id: '/$workspaceId/streams',
  path: '/$workspaceId/streams',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdActivitiesRoute = WorkspaceIdActivitiesImport.update({
  id: '/$workspaceId/activities',
  path: '/$workspaceId/activities',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/activities.lazy').then((d) => d.Route),
)

const ErrorErrorNoWorkspaceRoute = ErrorErrorNoWorkspaceImport.update({
  id: '/no-workspace',
  path: '/no-workspace',
  getParentRoute: () => ErrorErrorRoute,
} as any)

const ErrorErrorForbiddenWorkspaceRoute =
  ErrorErrorForbiddenWorkspaceImport.update({
    id: '/forbidden-workspace',
    path: '/forbidden-workspace',
    getParentRoute: () => ErrorErrorRoute,
  } as any)

const WorkspaceIdPartyPartyIdRoute = WorkspaceIdPartyPartyIdImport.update({
  id: '/$workspaceId/party/$partyId',
  path: '/$workspaceId/party/$partyId',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdStreamStreamIdRoutineRoute =
  WorkspaceIdStreamStreamIdRoutineImport.update({
    id: '/$workspaceId/stream/$streamId/routine',
    path: '/$workspaceId/stream/$streamId/routine',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdPortfolioRoute =
  WorkspaceIdStreamStreamIdPortfolioImport.update({
    id: '/$workspaceId/stream/$streamId/portfolio',
    path: '/$workspaceId/stream/$streamId/portfolio',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdClusteredRoute =
  WorkspaceIdStreamStreamIdClusteredImport.update({
    id: '/$workspaceId/stream/$streamId/clustered',
    path: '/$workspaceId/stream/$streamId/clustered',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdRouteImport.update({
    id: '/$workspaceId/stream/$streamId/process/$processId',
    path: '/$workspaceId/stream/$streamId/process/$processId',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$workspaceId/stream/$streamId/process/$processId/route.lazy'
    ).then((d) => d.Route),
  )

const WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdTimelineImport.update({
    id: '/timeline',
    path: '/timeline',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdStepsImport.update({
    id: '/steps',
    path: '/steps',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdNotesImport.update({
    id: '/notes',
    path: '/notes',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdHistoryImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsImport.update({
    id: '/attachments',
    path: '/attachments',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexImport.update({
    id: '/gates-and-steps/',
    path: '/gates-and-steps/',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdImport.update({
    id: '/step/$stepId',
    path: '/step/$stepId',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute =
  WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdImport.update({
    id: '/gates-and-steps/$stepId',
    path: '/gates-and-steps/$stepId',
    getParentRoute: () => WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/activities': {
      id: '/$workspaceId/activities'
      path: '/$workspaceId/activities'
      fullPath: '/$workspaceId/activities'
      preLoaderRoute: typeof WorkspaceIdActivitiesImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/streams': {
      id: '/$workspaceId/streams'
      path: '/$workspaceId/streams'
      fullPath: '/$workspaceId/streams'
      preLoaderRoute: typeof WorkspaceIdStreamsImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/workload': {
      id: '/$workspaceId/workload'
      path: '/$workspaceId/workload'
      fullPath: '/$workspaceId/workload'
      preLoaderRoute: typeof WorkspaceIdWorkloadImport
      parentRoute: typeof rootRoute
    }
    '/_error/forbidden-workspace': {
      id: '/_error/forbidden-workspace'
      path: '/forbidden-workspace'
      fullPath: '/forbidden-workspace'
      preLoaderRoute: typeof ErrorForbiddenWorkspaceImport
      parentRoute: typeof rootRoute
    }
    '/error': {
      id: '/error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorImport
      parentRoute: typeof rootRoute
    }
    '/error/_error': {
      id: '/error/_error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorErrorImport
      parentRoute: typeof ErrorRoute
    }
    '/$workspaceId/': {
      id: '/$workspaceId/'
      path: '/$workspaceId'
      fullPath: '/$workspaceId'
      preLoaderRoute: typeof WorkspaceIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/party/$partyId': {
      id: '/$workspaceId/party/$partyId'
      path: '/$workspaceId/party/$partyId'
      fullPath: '/$workspaceId/party/$partyId'
      preLoaderRoute: typeof WorkspaceIdPartyPartyIdImport
      parentRoute: typeof rootRoute
    }
    '/error/_error/forbidden-workspace': {
      id: '/error/_error/forbidden-workspace'
      path: '/forbidden-workspace'
      fullPath: '/error/forbidden-workspace'
      preLoaderRoute: typeof ErrorErrorForbiddenWorkspaceImport
      parentRoute: typeof ErrorErrorImport
    }
    '/error/_error/no-workspace': {
      id: '/error/_error/no-workspace'
      path: '/no-workspace'
      fullPath: '/error/no-workspace'
      preLoaderRoute: typeof ErrorErrorNoWorkspaceImport
      parentRoute: typeof ErrorErrorImport
    }
    '/$workspaceId/stream/$streamId/clustered': {
      id: '/$workspaceId/stream/$streamId/clustered'
      path: '/$workspaceId/stream/$streamId/clustered'
      fullPath: '/$workspaceId/stream/$streamId/clustered'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdClusteredImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/portfolio': {
      id: '/$workspaceId/stream/$streamId/portfolio'
      path: '/$workspaceId/stream/$streamId/portfolio'
      fullPath: '/$workspaceId/stream/$streamId/portfolio'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdPortfolioImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/routine': {
      id: '/$workspaceId/stream/$streamId/routine'
      path: '/$workspaceId/stream/$streamId/routine'
      fullPath: '/$workspaceId/stream/$streamId/routine'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdRoutineImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/process/$processId': {
      id: '/$workspaceId/stream/$streamId/process/$processId'
      path: '/$workspaceId/stream/$streamId/process/$processId'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/process/$processId/attachments': {
      id: '/$workspaceId/stream/$streamId/process/$processId/attachments'
      path: '/attachments'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/attachments'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/history': {
      id: '/$workspaceId/stream/$streamId/process/$processId/history'
      path: '/history'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/history'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdHistoryImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/notes': {
      id: '/$workspaceId/stream/$streamId/process/$processId/notes'
      path: '/notes'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/notes'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdNotesImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/steps': {
      id: '/$workspaceId/stream/$streamId/process/$processId/steps'
      path: '/steps'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/steps'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdStepsImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/timeline': {
      id: '/$workspaceId/stream/$streamId/process/$processId/timeline'
      path: '/timeline'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/timeline'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdTimelineImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId': {
      id: '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'
      path: '/gates-and-steps/$stepId'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/step/$stepId': {
      id: '/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
      path: '/step/$stepId'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/': {
      id: '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/'
      path: '/gates-and-steps'
      fullPath: '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexImport
      parentRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteImport
    }
  }
}

// Create and export the route tree

interface ErrorErrorRouteChildren {
  ErrorErrorForbiddenWorkspaceRoute: typeof ErrorErrorForbiddenWorkspaceRoute
  ErrorErrorNoWorkspaceRoute: typeof ErrorErrorNoWorkspaceRoute
}

const ErrorErrorRouteChildren: ErrorErrorRouteChildren = {
  ErrorErrorForbiddenWorkspaceRoute: ErrorErrorForbiddenWorkspaceRoute,
  ErrorErrorNoWorkspaceRoute: ErrorErrorNoWorkspaceRoute,
}

const ErrorErrorRouteWithChildren = ErrorErrorRoute._addFileChildren(
  ErrorErrorRouteChildren,
)

interface ErrorRouteChildren {
  ErrorErrorRoute: typeof ErrorErrorRouteWithChildren
}

const ErrorRouteChildren: ErrorRouteChildren = {
  ErrorErrorRoute: ErrorErrorRouteWithChildren,
}

const ErrorRouteWithChildren = ErrorRoute._addFileChildren(ErrorRouteChildren)

interface WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteChildren {
  WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute
  WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute
  WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute
  WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute
  WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute
  WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute
  WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute
  WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute
}

const WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteChildren: WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteChildren =
  {
    WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute,
    WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute:
      WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute,
  }

const WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren =
  WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute._addFileChildren(
    WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId/process/$processId': typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/stream/$streamId/process/$processId/attachments': typeof WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/stream/$streamId/process/$processId/history': typeof WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute
  '/$workspaceId/stream/$streamId/process/$processId/notes': typeof WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute
  '/$workspaceId/stream/$streamId/process/$processId/steps': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute
  '/$workspaceId/stream/$streamId/process/$processId/timeline': typeof WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/step/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId/process/$processId': typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/stream/$streamId/process/$processId/attachments': typeof WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/stream/$streamId/process/$processId/history': typeof WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute
  '/$workspaceId/stream/$streamId/process/$processId/notes': typeof WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute
  '/$workspaceId/stream/$streamId/process/$processId/steps': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute
  '/$workspaceId/stream/$streamId/process/$processId/timeline': typeof WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/step/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/_error/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorRouteWithChildren
  '/error/_error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId/': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/_error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/_error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId/process/$processId': typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/stream/$streamId/process/$processId/attachments': typeof WorkspaceIdStreamStreamIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/stream/$streamId/process/$processId/history': typeof WorkspaceIdStreamStreamIdProcessProcessIdHistoryRoute
  '/$workspaceId/stream/$streamId/process/$processId/notes': typeof WorkspaceIdStreamStreamIdProcessProcessIdNotesRoute
  '/$workspaceId/stream/$streamId/process/$processId/steps': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepsRoute
  '/$workspaceId/stream/$streamId/process/$processId/timeline': typeof WorkspaceIdStreamStreamIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/step/$stepId': typeof WorkspaceIdStreamStreamIdProcessProcessIdStepStepIdRoute
  '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/': typeof WorkspaceIdStreamStreamIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/forbidden-workspace'
    | '/error'
    | '/$workspaceId'
    | '/$workspaceId/party/$partyId'
    | '/error/forbidden-workspace'
    | '/error/no-workspace'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId/process/$processId'
    | '/$workspaceId/stream/$streamId/process/$processId/attachments'
    | '/$workspaceId/stream/$streamId/process/$processId/history'
    | '/$workspaceId/stream/$streamId/process/$processId/notes'
    | '/$workspaceId/stream/$streamId/process/$processId/steps'
    | '/$workspaceId/stream/$streamId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/forbidden-workspace'
    | '/error'
    | '/$workspaceId'
    | '/$workspaceId/party/$partyId'
    | '/error/forbidden-workspace'
    | '/error/no-workspace'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId/process/$processId'
    | '/$workspaceId/stream/$streamId/process/$processId/attachments'
    | '/$workspaceId/stream/$streamId/process/$processId/history'
    | '/$workspaceId/stream/$streamId/process/$processId/notes'
    | '/$workspaceId/stream/$streamId/process/$processId/steps'
    | '/$workspaceId/stream/$streamId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps'
  id:
    | '__root__'
    | '/'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/_error/forbidden-workspace'
    | '/error'
    | '/error/_error'
    | '/$workspaceId/'
    | '/$workspaceId/party/$partyId'
    | '/error/_error/forbidden-workspace'
    | '/error/_error/no-workspace'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId/process/$processId'
    | '/$workspaceId/stream/$streamId/process/$processId/attachments'
    | '/$workspaceId/stream/$streamId/process/$processId/history'
    | '/$workspaceId/stream/$streamId/process/$processId/notes'
    | '/$workspaceId/stream/$streamId/process/$processId/steps'
    | '/$workspaceId/stream/$streamId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/step/$stepId'
    | '/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  OnboardingRoute: typeof OnboardingRoute
  WorkspaceIdActivitiesRoute: typeof WorkspaceIdActivitiesRoute
  WorkspaceIdStreamsRoute: typeof WorkspaceIdStreamsRoute
  WorkspaceIdWorkloadRoute: typeof WorkspaceIdWorkloadRoute
  ErrorForbiddenWorkspaceRoute: typeof ErrorForbiddenWorkspaceRoute
  ErrorRoute: typeof ErrorRouteWithChildren
  WorkspaceIdIndexLazyRoute: typeof WorkspaceIdIndexLazyRoute
  WorkspaceIdPartyPartyIdRoute: typeof WorkspaceIdPartyPartyIdRoute
  WorkspaceIdStreamStreamIdClusteredRoute: typeof WorkspaceIdStreamStreamIdClusteredRoute
  WorkspaceIdStreamStreamIdPortfolioRoute: typeof WorkspaceIdStreamStreamIdPortfolioRoute
  WorkspaceIdStreamStreamIdRoutineRoute: typeof WorkspaceIdStreamStreamIdRoutineRoute
  WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute: typeof WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  OnboardingRoute: OnboardingRoute,
  WorkspaceIdActivitiesRoute: WorkspaceIdActivitiesRoute,
  WorkspaceIdStreamsRoute: WorkspaceIdStreamsRoute,
  WorkspaceIdWorkloadRoute: WorkspaceIdWorkloadRoute,
  ErrorForbiddenWorkspaceRoute: ErrorForbiddenWorkspaceRoute,
  ErrorRoute: ErrorRouteWithChildren,
  WorkspaceIdIndexLazyRoute: WorkspaceIdIndexLazyRoute,
  WorkspaceIdPartyPartyIdRoute: WorkspaceIdPartyPartyIdRoute,
  WorkspaceIdStreamStreamIdClusteredRoute:
    WorkspaceIdStreamStreamIdClusteredRoute,
  WorkspaceIdStreamStreamIdPortfolioRoute:
    WorkspaceIdStreamStreamIdPortfolioRoute,
  WorkspaceIdStreamStreamIdRoutineRoute: WorkspaceIdStreamStreamIdRoutineRoute,
  WorkspaceIdStreamStreamIdProcessProcessIdRouteRoute:
    WorkspaceIdStreamStreamIdProcessProcessIdRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/onboarding",
        "/$workspaceId/activities",
        "/$workspaceId/streams",
        "/$workspaceId/workload",
        "/_error/forbidden-workspace",
        "/error",
        "/$workspaceId/",
        "/$workspaceId/party/$partyId",
        "/$workspaceId/stream/$streamId/clustered",
        "/$workspaceId/stream/$streamId/portfolio",
        "/$workspaceId/stream/$streamId/routine",
        "/$workspaceId/stream/$streamId/process/$processId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/onboarding": {
      "filePath": "onboarding.tsx"
    },
    "/$workspaceId/activities": {
      "filePath": "$workspaceId/activities.tsx"
    },
    "/$workspaceId/streams": {
      "filePath": "$workspaceId/streams.tsx"
    },
    "/$workspaceId/workload": {
      "filePath": "$workspaceId/workload.tsx"
    },
    "/_error/forbidden-workspace": {
      "filePath": "_error/forbidden-workspace.tsx"
    },
    "/error": {
      "filePath": "error",
      "children": [
        "/error/_error"
      ]
    },
    "/error/_error": {
      "filePath": "error/_error.tsx",
      "parent": "/error",
      "children": [
        "/error/_error/forbidden-workspace",
        "/error/_error/no-workspace"
      ]
    },
    "/$workspaceId/": {
      "filePath": "$workspaceId/index.lazy.tsx"
    },
    "/$workspaceId/party/$partyId": {
      "filePath": "$workspaceId/party/$partyId.tsx"
    },
    "/error/_error/forbidden-workspace": {
      "filePath": "error/_error.forbidden-workspace.tsx",
      "parent": "/error/_error"
    },
    "/error/_error/no-workspace": {
      "filePath": "error/_error.no-workspace.tsx",
      "parent": "/error/_error"
    },
    "/$workspaceId/stream/$streamId/clustered": {
      "filePath": "$workspaceId/stream/$streamId/clustered.tsx"
    },
    "/$workspaceId/stream/$streamId/portfolio": {
      "filePath": "$workspaceId/stream/$streamId/portfolio.tsx"
    },
    "/$workspaceId/stream/$streamId/routine": {
      "filePath": "$workspaceId/stream/$streamId/routine.tsx"
    },
    "/$workspaceId/stream/$streamId/process/$processId": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/route.tsx",
      "children": [
        "/$workspaceId/stream/$streamId/process/$processId/attachments",
        "/$workspaceId/stream/$streamId/process/$processId/history",
        "/$workspaceId/stream/$streamId/process/$processId/notes",
        "/$workspaceId/stream/$streamId/process/$processId/steps",
        "/$workspaceId/stream/$streamId/process/$processId/timeline",
        "/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId",
        "/$workspaceId/stream/$streamId/process/$processId/step/$stepId",
        "/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/"
      ]
    },
    "/$workspaceId/stream/$streamId/process/$processId/attachments": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/attachments.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/history": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/history.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/notes": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/notes.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/steps": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/steps.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/timeline": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/timeline.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/step/$stepId": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/step/$stepId.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/": {
      "filePath": "$workspaceId/stream/$streamId/process/$processId/gates-and-steps/index.tsx",
      "parent": "/$workspaceId/stream/$streamId/process/$processId"
    }
  }
}
ROUTE_MANIFEST_END */
