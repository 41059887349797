import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { useEffect, useState } from "react";
import { Input } from "./ui/input";

// A debounced input react component
export const DebouncedInput = ({
	isLoading,
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number;
	onChange: (value: string | number) => void;
	isLoading?: boolean;
	debounce?: number;
} & Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"onChange"
>): JSX.Element => {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<div className="flex row items-center gap-1">
			<div>
				<FontAwesomeIcon
					spin={isLoading && !!value}
					style={
						isLoading && value
							? { color: "lightgray" }
							: { color: "transparent" }
					}
					icon={["fas", "spinner"]}
				/>
			</div>
			<Input
				{...props}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
		</div>
	);
};

export default DebouncedInput;
