import type { IModelReference } from "@metronome/types/ModelReference";
import type { IDispatch } from "@metronome/types/ProcessStream";
import type React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
	dispatch: IDispatch;
	children: React.ReactNode;
};

function getSpanOrLink(node: IModelReference): JSX.Element {
	return <span className="italic underline">{node.name}</span>;
}

function wordForLabels(dispatch: IDispatch): JSX.Element {
	return (
		<>
			{dispatch.labels?.length ? (
				<>
					<FormattedMessage id="WITHIN" />
					<span>Label(s)</span>
					{dispatch.labels.map((l) => (
						<span key={l.id} className="italic underline">
							{l.name} ({l.operation})
						</span>
					))}
				</>
			) : undefined}
		</>
	);
}

function wordForSpanMethod(dispatch: IDispatch): JSX.Element {
	return (
		<>
			<span className="italic underline">{dispatch?.level?.name}</span>
			{dispatch.node?.name ? (
				<>
					<FormattedMessage id="WITHIN" />
					{getSpanOrLink(dispatch.node)}
					{wordForLabels(dispatch)}
				</>
			) : undefined}
		</>
	);
}

export const DispatchWording: React.FC<Props> = ({ dispatch, children }) => {
	if (!dispatch) {
		return null;
	}
	return (
		<p className="flex gap-1 items-center">
			<FormattedMessage id="DISPATCH.BASE" />
			{wordForSpanMethod(dispatch)}
			{children}
		</p>
	);
};
