import { NotesTab } from '@metronome/features/Notes'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/$workspaceId/stream/$streamId/process/$processId/notes',
)({
  component: () => {
    const { processId } = Route.useParams()
    return <NotesTab context="process-instances" contextId={processId} />
  },
})
