import type { AxiosError } from "axios";
import { z } from "zod";

import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import {
	type IOrganization,
	Organizations,
} from "@metronome/types/Organization";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";

import api, { type ExpectedErrorResponseType, HTTPMethod } from "./api";
import { useLocation } from "@tanstack/react-router";

const GetOrganizationRequest = z.void();
const GetOrganizationResponse = Organizations;

const fetchOrganizations = api<
	z.infer<typeof GetOrganizationRequest>,
	z.infer<typeof GetOrganizationResponse>
>({
	method: HTTPMethod.enum.GET,
	requestSchema: GetOrganizationRequest,
	responseSchema: GetOrganizationResponse,
});

// these paths are workspace wide and not specific to an organization
const noOrganizationCallsPaths = ["/notifications", "/onboarding", "/error"];

const isEnabled = (path: string): boolean =>
	!noOrganizationCallsPaths.some((substr) => path.startsWith(substr));

export default function useOrganizations(): UseQueryResult<
	Array<Partial<IOrganization>>,
	AxiosError<ExpectedErrorResponseType>
> {
	const workspaceId = useWorkspaceId();
	const location = useLocation();

	return useQuery({
		queryKey: [workspaceId, "organizations"],
		queryFn: () => fetchOrganizations(`ws/${workspaceId}/organizations`),

		enabled: !!workspaceId && isEnabled(location.pathname),

		refetchOnWindowFocus: false,
		retry: false,
	});
}
