import type React from "react";

import { useNavigate } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";
import { useBatches } from "@metronome/api/useBatches";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import { type FC, useEffect, useState } from "react";
import { Route } from "@metronome/routes/$workspaceId/stream/$streamId/routine";
import { SelectResource } from "@metronome/features/SelectResource";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { StepInstanceFromCluster } from "./-stepsTimelineGrouped";
import { useStepInstancesFromRoutine } from "@metronome/api/useStepInstance";
import { useResourceAllocationsDefs } from "@metronome/api/useResourceAllocations";
import { Button } from "@metronome/components/ui/button";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { Badge } from "@metronome/components/ui/badge";
import type { IBatch } from "@metronome/types/Batches";

type StepInstancesByRessourceProps = {
	streamId: string;
	batchId: string;
	stepTemplateId: string;
	nodeReferenceId: string;
};
const StepInstancesByRessource: React.FC<StepInstancesByRessourceProps> = ({
	streamId,
	batchId,
	stepTemplateId,
	nodeReferenceId,
}) => {
	const { data, isLoading } = useStepInstancesFromRoutine({
		streamId,
		batchId,
		stepTemplateId,
		nodeReferenceId,
	});
	if (isLoading) return <LoadingMetronome />;
	if (data) return <StepInstanceFromCluster clusteredStepInstances={data} />;
	return null;
};

type GroupedByResources = {
	streamId: string;
	batchId: string;
};
export const RoutinesGroupedByResources: FC<GroupedByResources> = ({
	streamId,
	batchId,
}) => {
	const { data: resources, isLoading: isResourceLoading } =
		useResourceAllocationsDefs(streamId);
	const { nodeReferenceId, stepTemplateId } = Route.useSearch();
	if (isResourceLoading) return <LoadingMetronome />;
	if (!resources || resources?.length === 0) {
		return <span>no resource allocation have been found</span>;
	}
	return (
		<>
			<div className="flex items-center gap-2">
				<SelectResource
					resources={resources}
					nodeReferenceId={nodeReferenceId}
				/>
			</div>
			<div>
				<StepInstancesByRessource
					streamId={streamId}
					batchId={batchId}
					stepTemplateId={stepTemplateId ?? resources[0].stepTemplateId}
					nodeReferenceId={
						nodeReferenceId ?? resources[0].nodeReferenceDefinitionId
					}
				/>
			</div>
		</>
	);
};

type Props = {
	dateString: string;
};
const FormatDateForBatch: React.FC<Props> = ({ dateString }) => {
	const dateIsToday = isToday(dateString);
	if (dateIsToday) {
		return <FormattedMessage id="TODAY" />;
	}

	const dateIsTomorrow = isTomorrow(dateString);
	if (dateIsTomorrow) {
		return <FormattedMessage id="TOMORROW" />;
	}

	const dateIsYesterday = isYesterday(dateString);
	if (dateIsYesterday) {
		return <FormattedMessage id="YESTERDAY" />;
	}

	return dateString;
};

type BatchesMenuProps = {
	streamId: string;
};

type BatchProps = {
	selectedBatch?: string;
	batch: IBatch;
};
const Batch: React.FC<BatchProps> = ({ batch, selectedBatch }) => {
	const navigate = useNavigate();
	return (
		<li
			className={clsx({
				"rounded font-semibold border ": true,
				"bg-white text-primary border-primary border-solid":
					selectedBatch === batch.id,
				"border-dashed text-slate-500 border-slate-400":
					selectedBatch !== batch.id,
			})}
			key={batch.id}
		>
			<button
				className="px-2 py-1.5 "
				type="button"
				onClick={() =>
					navigate({
						from: "/$workspaceId/stream/$streamId/routine",
						to: "/$workspaceId/stream/$streamId/routine",
						search: {
							selectedBatch: selectedBatch === batch.id ? undefined : batch.id,
						},
					})
				}
			>
				<FormatDateForBatch dateString={batch.referenceDate} />
				<Badge
					variant={selectedBatch === batch.id ? "default" : "outline"}
					className={
						selectedBatch === batch.id
							? "text-xs px-1 ms-2 text-white border-primary"
							: "text-xs px-1 ms-2 text-slate-500 bg-white"
					}
				>
					{batch.state}
				</Badge>
			</button>
		</li>
	);
};

type Count = { countNext: number; countPrevious: number };
export const BatchesMenu: React.FC<BatchesMenuProps> = ({ streamId }) => {
	const navigate = useNavigate();
	const [count, setCount] = useState<Count>({ countNext: 3, countPrevious: 1 });
	const [cursorNext, setCursorNext] = useState<string | undefined>();
	const [cursorPrevious, setCursorPrevious] = useState<string | undefined>();
	const { selectedBatch } = Route.useSearch();
	const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
	const from = todayMidnight.toISOString().slice(0, 10);
	const { data: batches } = useBatches({
		streamId,
		from,
		countNext: count.countNext,
		countPrevious: count.countPrevious,
		cursorNext,
		cursorPrevious,
	});

	useEffect(() => {
		if (!selectedBatch) {
			navigate({
				from: "/$workspaceId/stream/$streamId/routine",
				to: "/$workspaceId/stream/$streamId/routine",
				search: {
					selectedBatch: batches?.currentBatches[0]?.id,
				},
			});
		}
	}, [selectedBatch, batches, navigate]);

	if (batches?.currentBatches.length)
		return (
			<div className="flex items-center gap-2 py-2">
				<Button
					onClick={() => {
						setCount((prev) => ({
							countNext: prev.countNext,
							countPrevious: prev.countPrevious + 1,
						}));
						setCursorPrevious(batches.previousBatches.nextCursor);
					}}
					variant="ghost"
					size="icon"
				>
					<ChevronLeftIcon />
				</Button>
				<ul className="flex flex-row items-center gap-4">
					{batches?.previousBatches?.results.map((b) => {
						return <Batch key={b.id} batch={b} selectedBatch={selectedBatch} />;
					})}
					{batches?.currentBatches.map((b) => {
						return <Batch key={b.id} batch={b} selectedBatch={selectedBatch} />;
					})}
					{batches?.nextBatches?.results.map((b) => {
						return <Batch key={b.id} batch={b} selectedBatch={selectedBatch} />;
					})}
				</ul>
				<Button
					variant="ghost"
					size="icon"
					onClick={() => {
						setCount((prev) => ({
							countNext: prev.countNext + 1,
							countPrevious: prev.countPrevious,
						}));
						setCursorNext(batches.nextBatches.nextCursor);
					}}
				>
					<ChevronRightIcon />
				</Button>
			</div>
		);
	return null;
};
