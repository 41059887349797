import type { IUser } from "@metronome/types/User";
import type { AxiosError, AxiosResponse } from "axios";
import {
	useMutation,
	type UseMutationResult,
	useQuery,
	useQueryClient,
	queryOptions,
} from "@tanstack/react-query";

import { apiGet, apiPut, type ExpectedErrorResponseType } from "./api";
import { useNavigate } from "@tanstack/react-router";
import { useOrganizationData } from "@metronome/context/OrganizationData";

export const selfQueryOptions = () =>
	queryOptions({
		queryKey: ["self"],
		queryFn: () => apiGet<IUser>("/self"),
		staleTime: Number.POSITIVE_INFINITY,
		gcTime: Number.POSITIVE_INFINITY,
	});

export default function useSelf() {
	return useQuery(selfQueryOptions());
}

export function useSelfUpdate(): UseMutationResult<
	AxiosResponse<IUser> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ defaultWorkspaceId?: string; defaultOrganizationId?: string }
> {
	const { data: self } = useSelf();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { setActiveOrganization } = useOrganizationData();
	return useMutation({
		mutationFn: ({ defaultWorkspaceId, defaultOrganizationId }) =>
			apiPut("self", {
				defaultWorkspaceId: defaultWorkspaceId ?? self?.defaultWorkspaceId,
				defaultOrganizationId,
			}),

		onSuccess: (response, { defaultWorkspaceId }) => {
			const { data } = response;
			queryClient.setQueryData(["self"], data);
			setActiveOrganization(data?.defaultOrganizationId);
			if (defaultWorkspaceId) navigate({ to: "/" });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["self"] });
		},
	});
}
