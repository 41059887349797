import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { Lozenge } from "@metronome/components/Lozenge";
import { EStepInstanceType, ISchedule } from "@metronome/types/StepInstance";
import {
	type ProgramItem,
	useProgram,
	ProgramBox,
	ProgramContent,
	ProgramFlex,
	ProgramStack,
	ProgramTitle,
} from "@nessprim/planby-pro";
import { Link } from "@tanstack/react-router";
import type { EResolution } from "@metronome/types/Resolution";
import { CheckCircledIcon, CircleIcon } from "@radix-ui/react-icons";
import { getMinutesBetweenDates } from "@metronome/utils/planby";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@metronome/components/ui/tooltip";

type ExtraProps = {
	showStepType?: boolean;
	showSchedule?: boolean;
};

function isSchedule(schedule: unknown): schedule is ISchedule {
	return Boolean(ISchedule.safeParse(schedule));
}

export const CustomItem = ({
	program,
	hourWidth,
	showSchedule,
	showStepType = true,
	...rest
}: ProgramItem & ExtraProps) => {
	const { isLive, styles } = useProgram({
		program,
		hourWidth,
		...rest,
	});

	const { data } = program;
	const {
		title,
		labels,
		since,
		type,
		id,
		processId,
		workspaceId,
		resolution,
		schedule,
	} = data;

	let distanceToLowerBond = 0;
	let distanceToUpperBond = 0;
	if (isSchedule(schedule)) {
		// fails silently and returns 0
		const minutesToLowerBond = schedule.plannedAt
			? getMinutesBetweenDates(schedule.scheduleLowerBand, schedule.plannedAt)
			: 0;
		// fails silently and returns 0
		const minutesToUpperBond = getMinutesBetweenDates(
			since,
			schedule.scheduleUpperBand,
		);

		const minutesWidth = hourWidth / 60;
		if (minutesToLowerBond) {
			distanceToLowerBond = minutesToLowerBond * minutesWidth;
		}
		if (minutesToUpperBond) {
			distanceToUpperBond = minutesToUpperBond * minutesWidth;
		}
	}

	if (type === EStepInstanceType.enum.milestone) {
		return (
			<ProgramBox
				as={"div"}
				style={styles.position}
				width={styles.width}
				className="translate-x-1/2 !bg-transparent relative !overflow-visible"
			>
				<div className="scale-150">
					<StepInstanceIcon type={type} />
				</div>
				<div className="absolute !z-50 r-0 t-0">
					<span>{title}</span>
				</div>
			</ProgramBox>
		);
	}

	return (
		<>
			{!!showSchedule && (
				<div
					className="absolute py-1"
					style={{
						transform: `translateX(-${String(distanceToLowerBond)}px)`,
						width: styles.position.left + distanceToUpperBond,
						top: styles.position.top,
						height: styles.position.height,
						left: styles.position.left,
					}}
				>
					<div className="bg-stripes w-full h-full p-1 flex justify-between group" />
				</div>
			)}
			<Tooltip open={styles.width > 150 ? false : undefined}>
				<TooltipTrigger asChild>
					<ProgramBox
						width={styles.width}
						style={styles.position}
						className="!z-[10] relative overflow-auto !min-w-[1.2rem] transition-all duration-300 ease-in-out before:h-10 !px-0 !py-0.5"
					>
						<ProgramContent
							className="!bg-gradient-to-r from-background to-background !border-primary !border !p-2"
							width={styles.width}
							isLive={isLive}
						>
							<ProgramFlex>
								<ProgramStack className="flex flex-col justify-between">
									<ProgramTitle as="div" className="flex items-center !m-0">
										{!!showStepType && (
											<span className="pe-2 text-slate-900">
												<StepInstanceIcon type={type} />
											</span>
										)}
										{(resolution as EResolution) === "done" ? (
											<CheckCircledIcon className="inline pe-1" />
										) : (
											<CircleIcon className="inline scale-90 pe-1" />
										)}
										<Link
											className="pe-2 text-slate-900"
											to="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId"
											params={{
												workspaceId,
												// streamId,
												processId,
												stepId: id,
											}}
										>
											{title}
										</Link>
										{(labels as string[])?.map((label) => (
											<Lozenge appearance="default" key={label}>
												{label}
											</Lozenge>
										))}
									</ProgramTitle>
								</ProgramStack>
							</ProgramFlex>
						</ProgramContent>
					</ProgramBox>
				</TooltipTrigger>
				<TooltipContent align="start" className="w-fit">
					<p>{title}</p>
				</TooltipContent>
			</Tooltip>
		</>
	);
};
