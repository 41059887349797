import clsx from "clsx";
import styles from "./tableContainer.module.scss";
import { useEffect, useRef } from "react";

const OFFSET = 55; // this is the height of the footer

type TableContainerProps = {
	children: React.ReactNode;
};

export const TableContainer: React.FC<TableContainerProps> = ({ children }) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.setProperty(
				"--distance-to-top",
				`${ref.current?.getBoundingClientRect().top + OFFSET}px`,
			);
		}
	});

	return (
		<div
			ref={ref}
			className={clsx({
				[styles.tableContainer]: true,
			})}
		>
			{children}
		</div>
	);
};
