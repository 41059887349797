import {
	type UseQueryResult,
	useQuery,
	queryOptions,
} from "@tanstack/react-query";
import { z } from "zod";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { IModelReference } from "@metronome/types/ModelReference";
import api, { HTTPMethod } from "./api";

const GetProcessCategoriesRequest = z.void();
const GetProcessCategoriesResponse = z.array(IModelReference);

const fetchProcessCategories = api<
	z.infer<typeof GetProcessCategoriesRequest>,
	z.infer<typeof GetProcessCategoriesResponse>
>({
	method: HTTPMethod.enum.GET,
	requestSchema: GetProcessCategoriesRequest,
	responseSchema: GetProcessCategoriesResponse,
});

export const processCategoriesOptions = (workspaceId: string) =>
	queryOptions({
		queryKey: [workspaceId, "processCategories"],
		queryFn: () =>
			fetchProcessCategories(`ws/${workspaceId}/process-categories`),
	});

export default function useProcessCategories(): UseQueryResult<
	IModelReference[] | undefined,
	Error
> {
	const workspaceId = useWorkspaceId();
	return useQuery(processCategoriesOptions(workspaceId));
}
