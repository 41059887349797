import React, { useCallback } from "react";

import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { EditableCell } from "@metronome/components/EditableCell";
import type {
	IBusinessDimensionNode,
	IMetadataValue,
} from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { getInputType } from "@metronome/utils/metadataType";
import { ComboBoxMultiSelect } from "@metronome/components/ComboBoxMultiSelect";

type EditSingleMetadataProps = {
	context: Context;
	contextId: string;
	node: IBusinessDimensionNode;
	metadataDefinition: IMetadataDefinition;
	query?: string;
};

type MultiSelectMetadataProps = {
	metadataDefinition: IMetadataDefinition;
	metaData?: IMetadataValue;
	nodeId?: string;
	mutateMetadata: ReturnType<typeof useUpsertMetadataValue>["mutate"];
};

export const MultiSelectMetadata: React.FC<MultiSelectMetadataProps> = ({
	metadataDefinition,
	metaData,
	nodeId,
	mutateMetadata,
}) => {
	const [value, setValue] = React.useState<string[] | number[]>(
		Array.isArray(metaData?.value)
			? metaData.value
			: metaData?.value
				? [metaData.value]
				: [],
	); // ugly, will need a refactor

	const [isDirty, setIsDirty] = React.useState(false);

	const onMenuToggle = useCallback(() => {
		if (isDirty && nodeId) {
			mutateMetadata({
				definitionId: metadataDefinition.id,
				nodeId: nodeId,
				metadataValues: [
					metaData?.id
						? {
								id: metaData.id,
								value,
							}
						: {
								value,
							},
				],
			});
		}
	}, [
		nodeId,
		isDirty,
		metaData?.id,
		metadataDefinition.id,
		mutateMetadata,
		value,
	]);

	if (metadataDefinition.enum)
		return (
			<ComboBoxMultiSelect
				items={metadataDefinition.enum.map((p) => ({
					label: p,
					value: p,
				}))}
				value={value}
				setValue={(value: string[]) => {
					setIsDirty(true);
					setValue(value);
				}}
				onClose={onMenuToggle}
			/>
		);

	return null;
};

export const EditSingleMetadata: React.FC<EditSingleMetadataProps> = ({
	context,
	contextId,
	metadataDefinition,
	node,
	query,
}) => {
	const { mutate: mutateMetadata } = useUpsertMetadataValue(contextId, context);

	const getMetaData = node?.metadataValues.find(
		(metaData) => metaData.definition.id === metadataDefinition.id,
	);

	if (metadataDefinition.type === "singleSelect") {
		return (
			<select
				className="w-full"
				value={getMetaData?.value}
				onChange={(e) => {
					if (node?.id && getMetaData?.id) {
						mutateMetadata({
							definitionId: getMetaData.definition.id,
							nodeId: node.id,
							metadataValues: [
								{
									id: getMetaData.id,
									value: e.target.value,
								},
							],
						});
					}
				}}
			>
				<option key="empty" />
				{metadataDefinition.enum?.map((v) => (
					<option key={v} value={v}>
						{v}
					</option>
				))}
			</select>
		);
	}

	if (metadataDefinition.type === "multiSelect") {
		if (
			metadataDefinition.enum &&
			getMetaData &&
			Array.isArray(getMetaData?.value)
		) {
			return (
				<MultiSelectMetadata
					metaData={getMetaData}
					nodeId={node.id}
					metadataDefinition={metadataDefinition}
					mutateMetadata={mutateMetadata}
				/>
			);
		}
	}

	if (typeof getMetaData?.value === "string") {
		return (
			<EditableCell
				value={getMetaData?.value}
				type={getInputType(metadataDefinition.type)}
				onValidate={(v: string) => {
					if (node?.id && getMetaData?.id) {
						mutateMetadata({
							definitionId: getMetaData.definition.id,
							nodeId: node.id,
							metadataValues: [{ id: getMetaData.id, value: v }],
						});
					}
				}}
			/>
		);
	}
	return <span>fail to parse data</span>;
};
