import { useOrganizationData } from "@metronome/context/OrganizationData";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	Metrics,
	MetricsCount,
	MetricsWorkloadByAssignee,
} from "@metronome/types/Metrics";
import {
	type UseQueryResult,
	useQuery,
	queryOptions,
} from "@tanstack/react-query";
import { apiPost } from "./api";

export type MetricsMethod = "count" | "duration";

interface UseMetricsParams {
	responsibleId?: string;
	from?: string;
	method: MetricsMethod;
	metrics: Record<
		"name",
		| "countOfActiveStepInstances"
		| "countOfAtRiskStepInstances"
		| "countOfLateStepInstances"
		| "countOfActiveProcessInstances"
		| "countOfAtRiskProcessInstances"
		| "countOfLateProcessInstances"
		| "stepInstanceWorkload"
		| "stepInstanceWorkloadByAssignee"
	>[];
}

interface MetricsOptionsParams extends UseMetricsParams {
	workspaceId: string;
	activeOrganization?: string;
}

export const metricsCountOptions = ({
	workspaceId,
	activeOrganization,
	responsibleId,
	from,
	method,
	metrics,
}: MetricsOptionsParams) =>
	queryOptions({
		queryKey: [
			workspaceId,
			activeOrganization,
			"count-metrics",
			responsibleId,
			metrics,
		],

		queryFn: () =>
			apiPost<MetricsCount[]>(`ws/${workspaceId}/metrics`, {
				organizationId: activeOrganization,
				responsibleId,
				from,
				method,
				metrics,
			}),

		select: (r) => r.data,
		retry: false,
	});

export function useMetricsCount({
	responsibleId,
	from,
	method,
	metrics,
}: UseMetricsParams): UseQueryResult<MetricsCount[], Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();


	return useQuery(
		metricsCountOptions({
			workspaceId,
			activeOrganization,
			responsibleId,
			from,
			method,
			metrics,
		}),
	);
}

interface UseMetricsWorkloadParams {
	from: string;
	method: MetricsMethod;
	metrics: Record<
		"name",
		"stepInstanceWorkload" | "stepInstanceWorkloadByAssignee"
	>[];
}

interface MetricsWorkloadOptionsParams extends UseMetricsWorkloadParams {
	workspaceId: string;
	activeOrganization?: string;
	enabled?: boolean;
}

export const metricsWorkloadOptions = ({
	workspaceId,
	activeOrganization,
	from,
	method,
	metrics,
}: MetricsWorkloadOptionsParams) =>
	queryOptions({
		queryKey: [
			workspaceId,
			activeOrganization,
			"workload-metrics",
			from,
			method,
			metrics,
		],

		queryFn: () =>
			apiPost<Metrics[]>(`ws/${workspaceId}/metrics`, {
				organizationId: activeOrganization,
				from,
				method,
				metrics,
			}),

		select: (r) => r.data,
		retry: false,
	});

export const metricsWorkloadByAssigneeOptions = ({
	workspaceId,
	activeOrganization,
	from,
	method,
	metrics,
	enabled = true,
}: MetricsWorkloadOptionsParams) =>
	queryOptions({
		queryKey: [
			workspaceId,
			activeOrganization,
			"workload-by-assignee-metrics",
			from,
			method,
			metrics,
		],

		queryFn: () =>
			apiPost<MetricsWorkloadByAssignee[]>(`ws/${workspaceId}/metrics`, {
				organizationId: activeOrganization,
				from,
				method,
				metrics,
			}),

		select: (r) => r.data,
		retry: false,
		enabled,
	});
