import useEntityHistories from '@metronome/api/useHistory'
import { PaginatedList } from '@metronome/components/PaginatedList'
import { defaultPagination } from '@metronome/constants/pagination'
import { HistoryTemplate } from '@metronome/features/History/HistoryTemplate'
import type { IPagination } from '@metronome/types/PaginatedResponse'
import type { IStepInstance } from '@metronome/types/StepInstance'
import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'

export const Route = createFileRoute(
  '/$workspaceId/stream/$streamId/process/$processId/history',
)({
  component: () => <History />,
})

const History: React.FC<{
  stepInstanceType?: IStepInstance['type']
}> = ({ stepInstanceType }) => {
  const { processId } = Route.useParams()
  const [pagination, setPagination] = useState<IPagination>({
    ...defaultPagination,
    page: Number.parseInt(location.hash.substring(1), 10) || 1,
  })
  const { data: histories } = useEntityHistories(
    'process-instances',
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    processId,
  )
  return (
    <PaginatedList
      pagination={pagination}
      totalPages={histories?.totalPages}
      onPageChange={(newPagination: IPagination) =>
        setPagination(newPagination)
      }
    >
      <HistoryTemplate
        stepInstanceType={stepInstanceType}
        histories={histories?.results}
      />
    </PaginatedList>
  )
}
