export const planbyLightTheme = {
	primary: {
		600: "#1a202c",
		900: "#ffffff",
	},
	grey: { 300: "#d1d1d1" },
	white: "#fff",
	teal: {
		100: "#38B2AC",
	},
	green: {
		200: "#389493",
		300: "#2C7A7B",
	},
	loader: {
		teal: "#5DDADB",
		purple: "#3437A2",
		pink: "#F78EB6",
		bg: "#171923db",
	},
	scrollbar: {
		border: "#ffffff",
		thumb: {
			bg: "#e1e1e1",
		},
	},
	gradient: {
		blue: {
			300: "hsla(var(--primary))",
			600: "hsla(var(--primary))",
			900: "hsla(var(--primary))",
		},
	},
	text: {
		grey: {
			300: "#718096",
			500: "#718096",
		},
	},
	timeline: {
		divider: {
			bg: "#718096",
		},
	},
	grid: {
		item: "#7180961a",
		divider: "#7180961a",
		highlight: "#38B2AC",
	},
};

export const EXPANDED_SIZE = 68;
export const COMPACT_SIZE = 36;
