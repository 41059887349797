import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import {
	ArrayOfRoleAndPartyAssignments,
	type RoleAndPartyAssignments,
	type Assignment,
} from "@metronome/types/PartyAssignment";
import type { AxiosResponse } from "axios";
import {
	type UseMutationResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import { apiDelete, apiGetWithSchema, apiPost, apiPut } from "./api";

const assignmentKeys = {
	process: (workspaceId: string, processId: string) =>
		[workspaceId, "process-instances", "assignments", processId] as const,
	step: (workspaceId: string, stepId: string) =>
		[workspaceId, "step-instances", "assignments", stepId] as const,
	everySteps: (workspaceId: string) =>
		[workspaceId, "step-instances", "assignments"] as const,
};

const AssignmentBaseParams = z.object({
	context: z.enum(["step-instances", "process-instances"]),
	contextId: z.string(),
});

type AssignmentBaseParams = z.infer<typeof AssignmentBaseParams>;

export function useStepInstanceAssignments(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();

	return useQuery({
		queryKey: assignmentKeys.step(workspaceId, stepInstanceId),
		queryFn: () =>
			apiGetWithSchema<ArrayOfRoleAndPartyAssignments>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/assignments`,
				ArrayOfRoleAndPartyAssignments,
			),
	});
}

export function useProcessAssignments(processId: string) {
	const workspaceId = useWorkspaceId();
	return useQuery({
		queryKey: assignmentKeys.process(workspaceId, processId),
		queryFn: () =>
			apiGetWithSchema<ArrayOfRoleAndPartyAssignments>(
				`ws/${workspaceId}/process-instances/${processId}/assignments`,
				ArrayOfRoleAndPartyAssignments,
			),
	});
}

export function useCreateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	applicationLevel: RoleAndPartyAssignments["applicationLevel"],
	onSuccess?: () => void,
): UseMutationResult<AxiosResponse | { data: undefined }, Error, Assignment> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ partyId, roleId }: Assignment) =>
			apiPost(`ws/${workspaceId}/${context}/${contextId}/assignments/`, {
				partyId,
				roleId,
				active: true,
			}),

		onSuccess: () => {
			if (context === "process-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.process(workspaceId, contextId),
				});
				if (applicationLevel === "step") {
					queryClient.invalidateQueries({
						queryKey: assignmentKeys.everySteps(workspaceId),
					});
				}
			} else if (context === "step-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.step(workspaceId, contextId),
				});
			}
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useDeleteAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,

	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse | { data: undefined },
	Error,
	{
		assignmentId: string;
		applicationLevel: RoleAndPartyAssignments["applicationLevel"];
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ assignmentId }) =>
			apiDelete(
				`ws/${workspaceId}/${context}/${contextId}/assignments/${assignmentId}`,
			),

		onSuccess: (_, { applicationLevel }) => {
			if (context === "process-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.process(workspaceId, contextId),
				});
				if (applicationLevel === "step") {
					queryClient.invalidateQueries({
						queryKey: assignmentKeys.everySteps(workspaceId),
					});
				}
			} else if (context === "step-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.step(workspaceId, contextId),
				});
			}
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useUpdateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse | { data: undefined },
	Error,
	{
		assignmentId: string;
		applicationLevel: RoleAndPartyAssignments["applicationLevel"];
		active: boolean;
		partyId: string;
		roleId: string;
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ assignmentId, partyId, roleId, active }) =>
			apiPut(
				`ws/${workspaceId}/${context}/${contextId}/assignments/${assignmentId}`,
				{
					active,
					partyId,
					roleId,
				},
			),

		onSuccess: (_, { applicationLevel }) => {
			if (context === "process-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.process(workspaceId, contextId),
				});
				if (applicationLevel === "step") {
					queryClient.invalidateQueries({
						queryKey: assignmentKeys.everySteps(workspaceId),
					});
				}
			} else if (context === "step-instances") {
				queryClient.invalidateQueries({
					queryKey: assignmentKeys.step(workspaceId, contextId),
				});
			}
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
