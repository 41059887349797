import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import type { ILightBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import { EditableMetadataValues } from "./MetadataList";
import { DisplayMetadataValue } from "./MetadataList/DisplayMetadataValues";
import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@metronome/components/Accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";

type FeaturedMetadataProps = {
	contextId: string;
	context: Context;
	businessDimension: ILightBusinessDimensionNode;
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
	contextId,
	context,
	businessDimension,
}) => {
	const { data: metaDataDefinitions, isLoading } = useMetadataDefinitions({
		nodeIds: [businessDimension.id],
	});

	if (isLoading) return null;

	if (metaDataDefinitions?.length === 0) return null;

	const featuredMetadataDefs = metaDataDefinitions?.filter(
		(def) => def.isFeatured,
	);

	if (
		featuredMetadataDefs?.length === 0 ||
		!Array.isArray(featuredMetadataDefs)
	)
		return null;

	return (
		<AccordionItem value="featured-metadata">
			<AccordionTrigger asChild>
				<div className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
					<ChevronDownIcon className="h-4 w-4 shrink-0 scale-110 transition-transform duration-200" />
					<span className="font-semibold text-base">
						<FormattedMessage id="FEATURED_METADATA" />
					</span>
				</div>
			</AccordionTrigger>
			<AccordionContent>
				<div className="">
					<div className="grid grid-cols-2 bg-slate-200 p-0 gap-[1px] rounded overflow-clip">
						{featuredMetadataDefs.map((def) => {
							const values = businessDimension.metadataValues.find(
								(value) => value.definition.id === def.id,
							);
							if (def.canBeEdited)
								return (
									<EditableMetadataValues
										nodeId={businessDimension.id}
										key={def.id}
										contextId={contextId}
										context={context}
										definition={def}
										values={values}
									/>
								);

							if (values)
								return (
									<DisplayMetadataValue
										key={def.id}
										metadataValue={values}
										definition={def}
									/>
								);
						})}
					</div>
				</div>
			</AccordionContent>
		</AccordionItem>
	);
};
