import type React from "react";
import { useCallback, useRef } from "react";

import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { Input } from "@metronome/components/ui/input";
import type { IMetadataValue } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { getInputType } from "@metronome/utils/metadataType";
import { MultiSelectMetadata } from "../EditSingleMetadata";

type EditableMetadataValuesProps = {
	contextId: string;
	context: Context;
	nodeId: string;
	definition: IMetadataDefinition;
	values?: IMetadataValue;
};

type EditModeMetadataValuesProps = {
	definition: IMetadataDefinition;
	contextId: string;
	context: Context;
	nodeId: string;
	values: IMetadataValue;
};

const EditModeMetadataValues: React.FC<EditModeMetadataValuesProps> = ({
	definition,
	contextId,
	context,
	nodeId,
	values,
}) => {
	const { mutate: mutateMetadataValues } = useUpsertMetadataValue(
		contextId,
		context,
	);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const submitInput: React.ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = useCallback(
		(e) => {
			if (!e.target.value || e.target.value === values?.value) {
				return;
			}
			mutateMetadataValues({
				definitionId: definition.id,
				nodeId,
				metadataValues: [{ value: e.target.value, id: values?.id }],
			});
		},
		[nodeId, definition.id, mutateMetadataValues, values?.id, values?.value],
	);

	return (
		<form className="w-full">
			{!!["text", "email", "date", "number", "integer", "url"].includes(
				definition.type,
			) && (
				<Input
					required
					onBlur={submitInput}
					name={definition.name}
					defaultValue={values?.value}
					placeholder={!values?.value ? `Remplir le ${definition.name}` : ""}
					type={getInputType(definition.type)}
					onClick={() => {
						if (getInputType(definition.type) === "date") {
							inputRef?.current?.showPicker();
						}
					}}
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
						e.key === "Enter" && e.preventDefault()
					}
					className=""
				/>
			)}
			{definition.type === "multiSelect" && (
				<MultiSelectMetadata
					metadataDefinition={definition}
					metaData={values}
					mutateMetadata={mutateMetadataValues}
					nodeId={nodeId}
				/>
			)}
			{definition.type === "singleSelect" && (
				<select
					className="w-full"
					name={definition.name}
					onChange={submitInput}
					key={definition.id}
				>
					{definition?.enum?.map((option) => (
						<option key={option}>{option}</option>
					))}
				</select>
			)}
		</form>
	);
};

export const EditableMetadataValues: React.FC<EditableMetadataValuesProps> = ({
	contextId,
	context,
	nodeId,
	definition,
	values,
}) => {
	return (
		<>
			<div
				key={`definition-${definition.id}`}
				className="bg-white flex items-center p-2"
			>
				<strong className="truncate font-semibold">{definition.name}</strong>
			</div>
			<div className="flex flex-row gap-1 flex-wrap bg-white p-2">
				<EditModeMetadataValues
					contextId={contextId}
					context={context}
					nodeId={nodeId}
					definition={definition}
					values={values as IMetadataValue}
				/>
			</div>
		</>
	);
};
