import { Badge } from "@metronome/components/ui/badge";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@metronome/components/ui/collapsible";
import { calcTillDate } from "@metronome/utils/planby";
import {
	ChevronRightIcon,
	CheckCircledIcon,
	CircleIcon,
} from "@radix-ui/react-icons";
import type { FC } from "react";
import { CustomItemSchedule } from "./UpdateStepInstanceSchedule";
import type { ClusteredStepInstances } from "@metronome/types/StepInstance";
import { Link } from "@tanstack/react-router";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";

type StepInstanceFromClusterProps = {
	clusteredStepInstances: ClusteredStepInstances;
	selectedMetadataDefs: string[];
};

export const GroupOfStepInstancesAsList: FC<
	Pick<
		StepInstanceFromClusterProps,
		"clusteredStepInstances" | "selectedMetadataDefs"
	>
> = ({ clusteredStepInstances, selectedMetadataDefs }) => {
	const workspaceId = useWorkspaceId();
	return (
		<div className="flex flex-col gap-2">
			{clusteredStepInstances.groups.map((g) => (
				<Collapsible defaultOpen key={g.resource.id}>
					<CollapsibleTrigger className="p-2 flex items-center gap-2 [&>svg]:data-[state=open]:rotate-90">
						<ChevronRightIcon className="inline" />
						{g.resource.name}
						<Badge variant="secondary" className="text-slate-400">
							{g.resource.tree.name}
						</Badge>
					</CollapsibleTrigger>
					<CollapsibleContent className="ps-8 flex flex-col gap-2">
						{g.stepInstances.map((s) => (
							<div
								key={s.id}
								className="flex items-center gap-2 w-fit bg-white rounded shadow-sm p-2"
							>
								{s.resolution === "done" ? (
									<CheckCircledIcon className="inline text-primary" />
								) : (
									<CircleIcon className="inline scale-90" />
								)}
								<Link
									from="/$workspaceId/stream/$streamId/clustered"
									to="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId"
									params={{
										workspaceId,
										processId: s.processInstanceId,
										stepId: s.id,
									}}
									className="pe-2 font-semibold"
								>
									{s.businessDimension.name}
								</Link>
								{!!selectedMetadataDefs?.length && (
									<div className="text-slate-500">
										{s.businessDimension.metadataValues
											.filter((v) =>
												selectedMetadataDefs.includes(v.definition.id),
											)
											.map((v) => (
												<span title={v.definition.name} key={v.id}>
													{v.value}
												</span>
											))}
									</div>
								)}
								<CustomItemSchedule
									stepId={s.id}
									schedule={s.schedule}
									since={s.schedule.plannedAt ?? s.schedule.scheduleLowerBand}
									till={calcTillDate(s.schedule)}
								/>
							</div>
						))}
					</CollapsibleContent>
				</Collapsible>
			))}
		</div>
	);
};
