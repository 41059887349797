import { singleProcessOptions } from "@metronome/api/useProcessInstance";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/process/$processId",
)({
	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
});
