import { DatePicker } from "@metronome/components/ui/datePicker";
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@metronome/components/ui/table";
import type { EResolution } from "@metronome/types/Resolution";
import type { ISchedule } from "@metronome/types/StepInstance";
import { FormattedMessage } from "react-intl";
import { CustomItemSchedule } from "./UpdateStepInstanceSchedule";
import { calcTillDate } from "@metronome/utils/planby";
import { Button } from "@metronome/components/ui/button";
import { toISOStringWithTimezone } from "@metronome/utils/dateHelpers";
import { formatDurationToString } from "@metronome/utils/durationHelpers";

type StepStateProps = {
	schedule: ISchedule;
	resolution: EResolution;
	stepInstanceId: string;
};
export const StepState: React.FC<StepStateProps> = ({
	schedule,
	resolution,
	stepInstanceId,
}) => {
	return (
		<Table className="bg-white">
			<TableBody>
				<TableRow>
					<TableCell>
						<FormattedMessage id="START" />
					</TableCell>
					<TableCell>
						<div className="flex items-center gap-2">
							<DatePicker
								initialDate={
									new Date(schedule.plannedAt ?? schedule.scheduleLowerBand)
								}
							/>
							<CustomItemSchedule
								stepId={stepInstanceId}
								since={schedule.plannedAt ?? schedule.scheduleLowerBand}
								till={calcTillDate(schedule)}
								schedule={schedule}
								childrenAsChild
							>
								<Button variant={"outline"}>
									{toISOStringWithTimezone(
										new Date(schedule.plannedAt ?? schedule.scheduleLowerBand),
									).slice(11, 16)}
								</Button>
							</CustomItemSchedule>
						</div>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<FormattedMessage id="END" />
					</TableCell>
					<TableCell>
						<DatePicker initialDate={new Date(schedule.scheduleUpperBand)} />
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<FormattedMessage id="DURATION" />
					</TableCell>
					<TableCell>
						{formatDurationToString(schedule.estimatedDuration) ||
							formatDurationToString(schedule?.plannedDuration)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<FormattedMessage id="STATUS" />
					</TableCell>
					<TableCell>
						<FormattedMessage id={`RESOLUTION.${resolution}`} />
					</TableCell>
				</TableRow>
				{/* <TableRow>
					<TableCell>
						<FormattedMessage id="ASSIGNEES" />
					</TableCell>
					<TableCell>"assignee"</TableCell>
				</TableRow> */}
			</TableBody>
		</Table>
	);
};
