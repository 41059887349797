import { BlockedIcon } from "@metronome/components/BlockedIcon";
import type { IMetadataValue } from "@metronome/types/BusinessDimension";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { Interweave } from "interweave";
import { EmailMatcher, UrlMatcher } from "interweave-autolink";
import React from "react";
import { FormattedMessage } from "react-intl";

type DisplayMetadataValueProps = {
	metadataValue: IMetadataValue;
	definition: IMetadataDefinition;
};
export const DisplayMetadataValue: React.FC<DisplayMetadataValueProps> = ({
	metadataValue,
	definition,
}) => {
	return (
		<React.Fragment key={`definition-${definition.id}`}>
			<div className="flex flex-row items-start gap-2 p-2 bg-white">
				<strong className="truncate font-semibold">{definition.name}</strong>
				<BlockedIcon tooltipMessage="METADATA_LOCKED" side="left" />
			</div>
			<div className="flex flex-row gap-1 flex-wrap p-2 bg-white">
				{typeof metadataValue?.value === "string" && (
					<Interweave
						key={`metadata-${metadataValue.id}`}
						content={metadataValue?.value}
						matchers={[new UrlMatcher("url"), new EmailMatcher("email")]}
					/>
				)}

				{Array.isArray(metadataValue?.value) && (
					<span>{metadataValue?.value.join(", ")}</span>
				)}

				{!metadataValue?.value && (
					<span className="italic">
						<FormattedMessage id="METADATA_NO_VALUE" />
					</span>
				)}
			</div>
		</React.Fragment>
	);
};
