import {
	singleProcessOptions,
	useStages,
} from "@metronome/api/useProcessInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { CustomChannelItem } from "@metronome/features/planby/CustomChannel";
import { StepsTimeline } from "@metronome/features/StepsTimeline";
import type { IStage } from "@metronome/types/Gate";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { useBuildStepsEpgFromStages } from "@metronome/utils/planby";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { endOfDay, startOfDay } from "date-fns";

type TimelineProps = {
	gates: IStage[];
	workspaceId: string;
	processInstance: IProcessInstance;
};
const Timeline: React.FC<TimelineProps> = ({
	gates,
	processInstance,
	workspaceId,
}) => {
	const { epg, channels } = useBuildStepsEpgFromStages(
		gates,
		workspaceId,
		processInstance,
	);

	return (
		<div className="p-2">
			<StepsTimeline
				key={processInstance.id}
				initialPlannedStart={startOfDay(
					processInstance.schedule.lowerTimeBand,
				).toISOString()}
				initialPlannedEnd={endOfDay(
					processInstance.schedule.upperTimeBand,
				).toISOString()}
				epg={epg}
				channels={channels}
				CustomChannelItem={CustomChannelItem}
			/>
		</div>
	);
};

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/process/$processId/timeline",
)({
	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
	component: () => {
		const { workspaceId, processId } = Route.useParams();
		const { data: processInstance } = useQuery(
			singleProcessOptions(workspaceId, processId),
		);
		const { data: gates } = useStages(processInstance?.id);

		if (processInstance?.schedule && gates?.length) {
			return (
				<Timeline
					gates={gates}
					workspaceId={workspaceId}
					processInstance={processInstance}
				/>
			);
		}
		return <LoadingMetronome />;
	},
});
