import {
	CurrentTime,
	type Timeline,
	TimelineWrapper,
	useTimeline,
	TimelineWeekMonthBox,
	TimelineWeekMonthDate,
	TimelineBox,
	TimelineDivider,
	TimelineDividers,
	TimelineTime,
} from "@nessprim/planby-pro";
import { FormattedDate } from "react-intl";

export function TimelineWithNav(props: Timeline) {
	const {
		time,
		dividers,
		timelineHeight,
		timelineDividers,
		getTime,
		getTimelineProps,
		getCurrentTimeProps,
		isWeekMonthMode,
	} = useTimeline(props);

	const { isToday, isBaseTimeFormat, isCurrentTime, isTimelineVisible } = props;

	const { hourWidth, dayWidth } = props;

	const width = isWeekMonthMode ? dayWidth : hourWidth;
	const renderTime = (item: string | number, index: number) => {
		const { isNewDay, time } = getTime(item);
		const position = { left: width * index, width: width };
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		return (
			<TimelineBox
				key={index}
				isToday={isToday}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				{...position}
			>
				<TimelineTime isNewDay={isNewDay} isBaseTimeFormat={isBaseTimeFormat}>
					{time}
				</TimelineTime>
				<TimelineDividers>{renderDividers(isNewDay)}</TimelineDividers>
			</TimelineBox>
		);
	};

	const renderDividers = (isNewDay: boolean) =>
		dividers.map((_, index) => (
			<TimelineDivider
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				key={index}
				isNewDay={isNewDay}
				width={width / timelineDividers}
				left={index * (width / timelineDividers)}
			/>
		));

	return (
		<TimelineWrapper className="relative" {...getTimelineProps()}>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{time.map((item, index) => renderTime(item, index))}
		</TimelineWrapper>
	);
}

export function WeekMonthTimelineWithNav(props: Timeline) {
	const { isWeekMonthMode, isMonthMode, time, ...rest } = useTimeline(props);
	const { timelineHeight, weekDayWidth, monthsWidth } = rest;
	const { getTimelineProps, getCurrentTimeProps } = rest;

	const { isToday, isCurrentTime, isRTL, isTimelineVisible } = props;
	const { mode } = props;

	const renderWeekMonth = (itemDate: string, index: number) => {
		const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
		const left = isMonthMode ? monthsWidth[index].left : width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					{isMonthMode ? (
						<span>
							<FormattedDate month="long" value={itemDate} />
						</span>
					) : (
						<>
							<span>
								<FormattedDate weekday="short" value={itemDate} />
							</span>
							<span>
								<FormattedDate day="numeric" value={itemDate} />
							</span>
						</>
					)}
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	return (
		<TimelineWrapper
			className="planby-timeline-wrapper relative"
			data-testid="timeline"
			{...getTimelineProps()}
		>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{time.map((item, index) => renderWeekMonth(item as string, index))}
		</TimelineWrapper>
	);
}
