import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	AttachmentAPI,
	AttachmentFileModel,
	AttachmentLinkModel,
	StepInstanceAttachments,
	UnsavedAttachmentFile,
	UnsavedAttachmentLink,
} from "@metronome/types/Attachment";
import type { AxiosError, AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import type { ExpectedErrorResponseType } from "./api";
import { apiDelete, apiForm, apiGet } from "./api";
import { stepInstanceKeys } from "./useStepInstance";

export interface BaseParams {
	contextId: string;
	context: "step-instances" | "process-instances";
}

export function useDeleteAttachment(): UseMutationResult<
	AxiosResponse<unknown> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ attachmentId: string; contextId: string; context: BaseParams["context"] }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ attachmentId, context, contextId }) =>
			apiDelete(
				`ws/${workspaceId}/${context}/${contextId}/attachments/${attachmentId}`,
			),

		onSuccess: (_, { context, contextId }) => {
			if (context === "step-instances" && contextId) {
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.requirements(workspaceId, contextId),
				});
			}
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId],
			});
		},
		onError: (err) => {
			toast.error(`Error: ${err.response?.data?.message}`);
		},
	});
}

type GetAttachmentResponseType<T> = T extends "step-instances"
	? StepInstanceAttachments
	: AttachmentAPI[];

export default function useAttachments<T>(
	context: BaseParams["context"],
	contextId?: string,
): UseQueryResult<GetAttachmentResponseType<T> | undefined, Error> {
	const workspaceId = useWorkspaceId();

	return useQuery({
		queryKey: [workspaceId, context, contextId, "attachments"],
		queryFn: () =>
			apiGet<GetAttachmentResponseType<BaseParams["context"]>>(
				`ws/${workspaceId}/${context}/${contextId}/attachments`,
			),

		enabled: !!contextId,
	});
}

export function useCreateFileAttachment(
	context: BaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse<AttachmentFileModel | { data: undefined }> | undefined,
	Error,
	UnsavedAttachmentFile
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (newAttachment: UnsavedAttachmentFile) =>
			apiForm(
				`ws/${workspaceId}/${context}/${contextId}/attachments/`,
				"post",
				{
					type: newAttachment.type,
					name: newAttachment.name,
					description: newAttachment.description,
					file: newAttachment?.file,
					previewFile: newAttachment?.previewFile,
					specId: newAttachment?.specId,
				},
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId],
			});
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, contextId),
			});

			onSuccess?.();
		},
	});
}

export function useCreateLinkAttachment(
	context: BaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse<AttachmentLinkModel> | { data: undefined } | undefined,
	Error,
	UnsavedAttachmentLink
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (newAttachment: UnsavedAttachmentLink) =>
			apiForm(
				`ws/${workspaceId}/${context}/${contextId}/attachments/`,
				"post",
				{
					type: newAttachment.type,
					link: encodeURI(newAttachment.link),
					name: newAttachment.name,
					description: newAttachment.description,
					specId: newAttachment.specId,
				},
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId],
			});
			if (context === "step-instances" && contextId) {
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.single(workspaceId, contextId),
				});
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.requirements(workspaceId, contextId),
				});
			}
			onSuccess?.();
		},
	});
}

export function useUpdateLinkAttachment(
	context: BaseParams["context"],
	contextId: string,
): UseMutationResult<
	AxiosResponse<AttachmentLinkModel> | { data: undefined } | undefined,
	AxiosError<ExpectedErrorResponseType>,
	AttachmentLinkModel
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (newAttachment: AttachmentLinkModel) =>
			apiForm(
				`ws/${workspaceId}/${context}/${contextId}/attachments/${newAttachment.id}`,
				"put",
				{
					type: newAttachment.type,
					link: encodeURI(newAttachment.link),
					name: newAttachment.name,
					description: newAttachment.description,
				},
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId],
			});
			if (context === "step-instances") {
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.single(workspaceId, contextId),
				});
			}
		},
	});
}
