import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAttachments from "@metronome/api/useAttachments";
import Accordion, {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@metronome/components/Accordion";
import { Attachment } from "@metronome/components/Attachment";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import SectionMessage from "@metronome/components/SectionMessage";
import type {
	PendingAttachmentRequirementAPI,
	StepInstanceAttachments,
} from "@metronome/types/Attachment";
import type {
	Context,
	ProcessInstance,
	StepInstances,
} from "@metronome/types/Context";
import type React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SendFileAttachmentAsDialog } from "../SendFileAttachment";
import { SendLinkAttachmentAsDialog } from "../SendLinkAttachment";
import styles from "./requiredAttachments.module.scss";

interface Props {
	context: Context;
	contextId: string;
	children?: React.ReactNode;
}

interface RequiredAttachmentsCoreProps extends Props {
	onSuccess: () => void;
	attachments?: StepInstanceAttachments;
}
export const RequiredAttachmentsCore: React.FC<
	RequiredAttachmentsCoreProps
> = ({ attachments, context, contextId, onSuccess }) => {
	const intl = useIntl();

	const fulfilledAttachments = attachments?.required?.filter(
		(attached) => attached.attachment,
	);

	const pendingAttachments = attachments?.required?.filter(
		(attached) => !attached.attachment,
	) as PendingAttachmentRequirementAPI[];

	return (
		<>
			<p>
				{intl.formatMessage({
					id: "REQUIRED_ATTACHMENTS_DESCRIPTION",
				})}
			</p>
			<div className="flex flex-col gap-2">
				{!pendingAttachments.length && (
					<SectionMessage appearance="success">
						<FormattedMessage id="ALL_REQUIRED_ATTACHMENTS_SUBMITTED" />
					</SectionMessage>
				)}
				<Accordion type="multiple">
					<div className="flex flex-col gap-2">
						{pendingAttachments.map((attachment) => (
							<AccordionItem
								className={styles.mainContainer}
								key={attachment.id}
								value={attachment.id}
							>
								<AccordionTrigger asChild>
									<div key={attachment.id} className={styles.container}>
										<div className="flex flex-row items-center gap-2">
											<FontAwesomeIcon
												fixedWidth
												icon={[
													"fas",
													attachment.type === "Link" ? "link" : "upload",
												]}
											/>
											{attachment.name}
										</div>
										{/** warning look at the css selectors for span */}
										<span className="underline me-auto ps-2">
											<FormattedMessage id="INFO" />
										</span>

										{attachment.type === "Link" && (
											<SendLinkAttachmentAsDialog
												context={context}
												contextId={contextId}
												specId={attachment.id}
												onSuccess={onSuccess}
												required
											>
												<FontAwesomeIcon
													fixedWidth
													className="text-white me-1"
													icon={["fas", "circle-exclamation"]}
												/>
												<FormattedMessage id="SEND_ATTACHMENT_LINK" />
											</SendLinkAttachmentAsDialog>
										)}
										{attachment.type === "Document" && (
											<SendFileAttachmentAsDialog
												context={context}
												contextId={contextId}
												specId={attachment.id}
												onSuccess={onSuccess}
											>
												<FontAwesomeIcon
													fixedWidth
													className="text-white me-1"
													icon={["fas", "circle-exclamation"]}
												/>
												<FormattedMessage id="SEND_ATTACHMENT_FILE" />
											</SendFileAttachmentAsDialog>
										)}
									</div>
								</AccordionTrigger>
								<AccordionContent asChild>
									<div className="mt-2">
										<div>
											<strong>
												<FormattedMessage id="DESCRIPTION" />:
											</strong>
										</div>
										<span className="italic">{attachment.description}</span>
									</div>
								</AccordionContent>
							</AccordionItem>
						))}
					</div>
				</Accordion>
				<div>
					{fulfilledAttachments?.map((attachment) => (
						<Attachment
							key={attachment.id}
							attachment={attachment.attachment}
							context={context}
							contextId={contextId}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export const RequiredAttachmentsAsModal: React.FC<Props> = ({
	context,
	contextId,
	children,
}) => {
	const [open, setOpen] = useState(false);

	type ActualContext<T> = T extends "process-instances"
		? ProcessInstance
		: StepInstances;

	const { data: attachments } = useAttachments<ActualContext<"step-instances">>(
		context,
		contextId,
	);

	if (!Array.isArray(attachments) && !attachments?.required.length) return null;

	const pendingAttachments = attachments?.required?.filter(
		(attached) => !attached.attachment,
	) as PendingAttachmentRequirementAPI[];

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger className="me-auto" asChild>
				{children ?? (
					<button type="button" className={styles.button}>
						<FontAwesomeIcon fixedWidth icon={["fas", "file"]} />
						{!!pendingAttachments.length && (
							<div className={styles.dotButton} />
						)}
					</button>
				)}
			</DialogTrigger>
			<DialogContent size="small">
				<RequiredAttachmentsCore
					attachments={attachments}
					context={context}
					contextId={contextId}
					onSuccess={() => setOpen(false)}
				/>
			</DialogContent>
		</Dialog>
	);
};
