import {
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@metronome/components/ui/table";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { useMemo } from "react";

type NodesReferencesProps = {
	streamId: string;
	nodeReferences: IStepInstance["nodeReferences"];
};
/**
 * NodesReferences lacks a way to navigate or show the details of a node: open a popover with
 * the nodes metadata and detail for example
 */
export const NodesReferences: React.FC<NodesReferencesProps> = ({
	nodeReferences,
	streamId,
}) => {
	const nodes = useMemo(() => {
		return nodeReferences
			?.map((nr) => nr.referencedNode)
			.sort((a, b) => a.tree.name.localeCompare(b.tree.name));
	}, [nodeReferences]);

	if (nodes?.length === 0) {
		return null;
	}

	return (
		<Table className="bg-white">
			<TableBody>
				{nodes?.map((node) => (
					<TableRow key={node.id}>
						<TableCell>{node.tree.type}</TableCell>
						<TableCell className="font-medium">{node.name}</TableCell>
						<TableCell>{node.tree.description}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
