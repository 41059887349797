import { useUpdateLinkAttachment } from "@metronome/api/useAttachments";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import FloatingInput from "@metronome/components/FloatingInput";
import {
	type AttachmentAPI,
	AttachmentTypeAPI,
} from "@metronome/types/Attachment";
import type { ProcessInstance, StepInstances } from "@metronome/types/Context";
import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./editLinkAttachment.module.scss";
import { DialogTitle } from "@metronome/components/ui/dialog";

type Props = {
	context: ProcessInstance | StepInstances;
	contextId: string;
	attachment: AttachmentAPI;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditLinkAttachment = ({
	context,
	contextId,
	attachment,
	open,
	setOpen,
}: Props): JSX.Element => {
	const intl = useIntl();

	const { mutateAsync: updateAttachment, isPending } = useUpdateLinkAttachment(
		context,
		contextId,
	);

	const handleSubmit: React.ChangeEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const data = {} as Record<string, string>;

		for (const [key, value] of formData.entries()) {
			if (typeof value === "string") {
				data[key] = value;
			}
		}
		updateAttachment({
			id: attachment.id,
			type: AttachmentTypeAPI.enum.Link,
			link: data.link,
			name: data.name,
			description: data.description ?? "",
		}).finally(() => {
			setOpen(false);
		});
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogTitle>
					<FormattedMessage id="EDIT_LINK_ATTACHMENT" />
				</DialogTitle>
				<form className="flex flex-col gap-2 p-4" onSubmit={handleSubmit}>
					<FloatingInput
						required
						label="link"
						name="link"
						defaultValue={attachment.content}
					/>
					<FloatingInput
						required
						label="name"
						name="name"
						defaultValue={attachment.name}
					/>
					<FloatingInput
						required={false}
						label="description"
						name="description"
						defaultValue={attachment.description}
					/>
					<input
						type="submit"
						disabled={isPending}
						className={styles.submit}
						value={
							isPending
								? intl.formatMessage({ id: "LOADING" })
								: intl.formatMessage({ id: "EDIT" })
						}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};
