import { ProgressBar } from "@metronome/components/Progress";
import { TimelinessLozenge } from "@metronome/components/TimelinessTag";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import type { EResolution } from "@metronome/types/Resolution";
import { getHexColorFromStatusClass } from "@metronome/utils/status";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";
import { ChannelBox } from "@nessprim/planby-pro";
import type { ChannelWithPosition } from "@nessprim/planby-pro/dist/Epg/helpers/types";
import { Link } from "@tanstack/react-router";
import { isThisYear } from "date-fns";
import type { FC } from "react";
import { FormattedDateTimeRange } from "react-intl";

type ChannelScheduleProps = {
	schedule: IProcessInstance["schedule"];
};
const ChannelSchedule: FC<ChannelScheduleProps> = ({ schedule }) => {
	return (
		<span className="text-xs  truncate">
			<FormattedDateTimeRange
				day="numeric"
				month="short"
				weekday="short"
				hour="2-digit"
				minute="2-digit"
				year={isThisYear(schedule.lowerTimeBand) ? "numeric" : undefined}
				from={new Date(schedule.lowerTimeBand)}
				to={new Date(schedule.upperTimeBand)}
			/>
		</span>
	);
};

export const CustomChannelItem: React.FC<{
	channel: ChannelWithPosition;
	children: React.ReactNode;
	displayMode: "compact" | "expanded";
}> = ({ channel, children, displayMode }) => {
	const { position, title, uuid, progress, schedule, resolution, workspaceId } =
		channel;
	return (
		<>
			<ChannelBox
				className="p-1 bg-slate-400 z-50 gap-2 items-start"
				{...position}
			>
				<div className="shadow border rounded flex flex-col p-0.5 w-full self-start h-[stretch]">
					{displayMode === "expanded" && (
						<div className="flex items-center gap-2">
							<TimelinessLozenge
								timeliness={getTimelinessFromTimeline(schedule)}
							/>
							<ProgressBar
								key={uuid}
								progress={progress ?? 0}
								colorCouple={getHexColorFromStatusClass(
									resolution as EResolution,
									getTimelinessFromTimeline(schedule),
								)}
								className="h-1"
							/>
						</div>
					)}
					<Link
						to="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps"
						params={{ workspaceId, processId: uuid }}
						className="text-slate-600 hover:text-primary font-semibold truncate"
					>
						{title}
					</Link>
					{displayMode === "expanded" && (
						<ChannelSchedule schedule={schedule} />
					)}
				</div>
			</ChannelBox>
			{children}
		</>
	);
};
