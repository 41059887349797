import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiGet } from "./api";
import type { CursoredBatches } from "@metronome/types/Batches";

type BatchesPayload = {
	streamId: string;
	from?: string;
	countNext?: number;
	countPrevious?: number;
	cursorNext?: string;
	cursorPrevious?: string;
};
export function useBatches({
	streamId,
	from,
	countNext,
	countPrevious,
	cursorNext,
	cursorPrevious,
}: BatchesPayload) {
	const workspaceId = useWorkspaceId();
	const today = new Date().toISOString().slice(0, 10);

	return useQuery({
		queryKey: [
			workspaceId,
			streamId,
			"batches",
			from,
			countNext,
			countPrevious,
			cursorNext,
			cursorPrevious,
		],
		queryFn: () =>
			apiGet<CursoredBatches>(
				`/ws/${workspaceId}/process-streams/${streamId}/batches`,
				{
					params: {
						currentDate: from ?? today,
						countNext,
						countPrevious,
						cursorNext,
						cursorPrevious,
					},
				},
			),
		placeholderData: keepPreviousData,
	});
}
