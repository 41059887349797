import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadixPopover from "@radix-ui/react-popover";
import * as Tabs from "@radix-ui/react-tabs";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import { fr } from "date-fns/locale/fr";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";

import {
	useInfiniteNotifications,
	useReadAllNotificationMutation,
	useToggleReadNotificationMutation,
} from "@metronome/api/useNotifications";
import { Button } from "@metronome/components/ui/button";
import type {
	BusinessType,
	INotification,
	Links,
	Parameters,
} from "@metronome/types/Notifications";
import type { ICursorPaginatedResults } from "@metronome/types/PaginatedResponse";
import formatDate from "@metronome/utils/formatDate";

import styles from "./notifications.module.scss";
import { useWebsocketContext } from "@metronome/context/WebSocketContext";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

const locales = { fr };

const BUSINESS_ALERT = "BusinessAlert";
const BUSINESS_ACTIVITY = "BusinessActivity";

type LinkWrapperNotificationsProps = {
	links: Links;
	children: React.ReactNode;
};
const LinkWrapperNotifications: React.FC<LinkWrapperNotificationsProps> = ({
	links,
	children,
}) => {
	if (links?.stepInstanceId) {
		return (
			<Link
				className={styles.itemStyle}
				to="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId"
				params={{
					workspaceId: links.workspaceId,
					streamId: links.processStreamId,
					processId: links.processInstanceId,
					stepId: links.stepInstanceId,
				}}
			>
				{children}
			</Link>
		);
	}

	if (links?.processInstanceId) {
		return (
			<Link
				className={styles.itemStyle}
				to="/$workspaceId/stream/$streamId/process/$processId"
				params={{
					workspaceId: links.workspaceId,
					processId: links.processInstanceId,
					streamId: links.processStreamId,
				}}
			>
				{children}
			</Link>
		);
	}
	return null;
};

export const SingleNotificationElement = ({
	template,
	when,
	parameters,
	isRead,
	links,
	setReadStatus,
	notificationId,
}: {
	template: string;
	parameters: Parameters;
	when: number;
	isRead: boolean;
	links: Links;
	setReadStatus: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		notificationId: string,
		isRead: boolean,
	) => void;
	notificationId: string;
}): JSX.Element => {
	const intl = useIntl();

	let notifTime: string;
	try {
		const date = new Date(when);
		notifTime = formatDistanceToNow(date, {
			addSuffix: true,
			locale: locales[intl?.locale as keyof typeof locales],
		});
	} catch {
		notifTime = "N/A";
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const memoizedTitle = useMemo(() => {
		let result = template;
		Object.keys(parameters).forEach((key) => {
			if (key === "stepType" && parameters[key]) {
				result = result.replace(
					new RegExp(`{${key}}`, "g"),
					intl.formatMessage({ id: parameters[key]?.toLowerCase() }),
				);
			} else if (key === "userName" && parameters[key]) {
				// here we remove the username from the template to style it in the render below
				result = result.replace(new RegExp(`{${key}}`, "g"), "");
			} else {
				result = result.replace(
					new RegExp(`{${key}}`, "g"),
					parameters[key as keyof typeof parameters],
				);
			}
		});
		return result;
	}, [template]);

	return (
		<LinkWrapperNotifications links={links} key={notificationId}>
			<div className={styles.container}>
				<div title={memoizedTitle} className={styles.titleContainer}>
					<span className="font-bold">{parameters.userName}</span>
					{memoizedTitle}
				</div>
				<div
					className="text-xs flex-shrink-0"
					title={formatDate(when, "PPpp", {
						locale: intl.locale,
					})}
				>
					{notifTime}
				</div>
				<button
					type="button"
					onClick={(e) => setReadStatus(e, notificationId, isRead)}
					className="ps-2 border-none"
					title="set read status"
				>
					<FontAwesomeIcon
						icon="dot-circle"
						className={isRead ? styles.iconRead : styles.icon}
					/>
				</button>
			</div>
		</LinkWrapperNotifications>
	);
};

export const NotificationsList = ({
	notifications,
	isLoading,
}: {
	notifications: (ICursorPaginatedResults<INotification> | undefined)[];
	isLoading: boolean;
}): JSX.Element => {
	const intl = useIntl();

	const { mutate } = useToggleReadNotificationMutation();

	const setReadStatus = useCallback(
		(
			e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
			notificationId: string,
			isRead: boolean,
		) => {
			e.preventDefault();
			mutate({ notificationId, isRead });
		},
		[mutate],
	);

	if (isLoading) {
		return <FontAwesomeIcon icon={["fas", "circle-dot"]} beatFade />;
	}
	if (!Array.isArray(notifications) || notifications.length === 0)
		return <span>{intl.formatMessage({ id: "NOTIFICATIONS.ERROR" })}</span>;

	return (
		<div>
			{notifications?.map((notif) =>
				notif?.results?.map((singlenotif) => (
					<React.Fragment key={singlenotif.notificationId}>
						<SingleNotificationElement
							notificationId={singlenotif.notificationId}
							template={singlenotif.template}
							parameters={singlenotif.metadata.parameters}
							when={singlenotif.notificationTime}
							isRead={singlenotif.isRead}
							links={singlenotif.metadata.links}
							setReadStatus={setReadStatus}
						/>
					</React.Fragment>
				)),
			)}
		</div>
	);
};

export const NotificationsContent: React.FC<{
	type: BusinessType;
	setType: (value: BusinessType) => void;
}> = ({ type, setType }) => {
	const intl = useIntl();
	const { ref, inView } = useInView();

	const { mutate } = useReadAllNotificationMutation();
	const markAllAsRead = useCallback(() => mutate(undefined), [mutate]);

	const { data, error, fetchNextPage, hasNextPage, isLoading } =
		useInfiniteNotifications(type);

	const onNotificationTypeChange = useCallback(
		(value: BusinessType) => {
			setType(value);
		},
		[setType],
	);

	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage]);

	return (
		<>
			<div className={styles.title_container}>
				<h2 className="text-base font-semibold">
					{intl.formatMessage({ id: "NOTIFICATIONS" })}
				</h2>
				<Button onClick={markAllAsRead} variant="link">
					{intl.formatMessage({ id: "NOTIFICATIONS.MARK_ALL_READ" })}
				</Button>
			</div>
			<Tabs.Root
				onValueChange={(value: string) =>
					onNotificationTypeChange(value as BusinessType)
				}
				defaultValue={BUSINESS_ALERT}
				value={type}
			>
				<Tabs.List className={styles.tab_list} aria-label="Manage your account">
					<Tabs.Trigger className={styles.tab_trigger} value={BUSINESS_ALERT}>
						<strong>
							{intl.formatMessage({ id: "NOTIFICATIONS.ALERTS" })}
						</strong>
					</Tabs.Trigger>
					<Tabs.Trigger
						className={styles.tab_trigger}
						value={BUSINESS_ACTIVITY}
					>
						<strong>
							{intl.formatMessage({ id: "NOTIFICATIONS.ACTIVITY" })}
						</strong>
					</Tabs.Trigger>
				</Tabs.List>
				<Tabs.Content key={type} value={BUSINESS_ALERT}>
					{data?.pages?.length && (
						<NotificationsList
							isLoading={isLoading}
							notifications={data.pages}
						/>
					)}
				</Tabs.Content>
				<Tabs.Content value={BUSINESS_ACTIVITY}>
					{data?.pages?.length && (
						<NotificationsList
							isLoading={isLoading}
							notifications={data.pages}
						/>
					)}
				</Tabs.Content>
			</Tabs.Root>
			{error && <div>{intl.formatMessage({ id: "NOTIFICATIONS.ERROR" })}</div>}
			<div ref={ref} className="flex items-center justify-center p-4">
				{(hasNextPage || isLoading) && (
					<FontAwesomeIcon icon={["fas", "circle-dot"]} beatFade />
				)}
				{!hasNextPage && !isLoading && (
					<span>
						{intl.formatMessage({ id: "NOTIFICATIONS.NO_MORE_TO_LOAD" })}
					</span>
				)}
			</div>
		</>
	);
};

export const Notifications = (): JSX.Element => {
	const [type, setType] = useState<BusinessType>(BUSINESS_ALERT);
	const [open, setOpen] = useState<boolean>(false);
	const [dirty, setDirty] = useState<boolean>(false);
	const [subscribe, unsubscribe] = useWebsocketContext();
	const queryClient = useQueryClient();

	useEffect(() => {
		// channelName should be a constant stored in a shared file
		const channelName = "NOTIFICATIONS";

		subscribe(channelName, (message?: unknown) => {
			// do a proper check here
			if (message) {
				setDirty(true);
			}
		});

		return () => {
			unsubscribe(channelName);
		};
	}, [subscribe, unsubscribe]);

	const onOpenChange = useCallback(
		(open: boolean) => {
			if (open) {
				queryClient.invalidateQueries({
					queryKey: ["notifications"],
				});
			}
			setOpen(open);
			setDirty(false);
		},
		[queryClient],
	);

	return (
		<RadixPopover.Root open={open} onOpenChange={onOpenChange}>
			<RadixPopover.Trigger asChild>
				<div className={styles.iconButton}>
					{!!dirty && <div className={styles.notif_dot} />}
					<FontAwesomeIcon
						icon="bell"
						className={styles.bellIcon}
						transform={{ rotate: 42 }}
					/>
				</div>
			</RadixPopover.Trigger>
			<RadixPopover.Content className={styles.contentStyle}>
				<NotificationsContent type={type} setType={setType} />
			</RadixPopover.Content>
		</RadixPopover.Root>
	);
};

export default Notifications;
