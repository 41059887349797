import { SchedulerError } from "@metronome/components/SchedulerError";
import {
	Epg,
	Layout,
	type Program,
	useEpg,
	type Zone,
} from "@nessprim/planby-pro";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import { Slider } from "@metronome/components/ui/slider";
import { FormattedDateTimeRange, FormattedMessage } from "react-intl";
import {
	TimelineWithNav,
	WeekMonthTimelineWithNav,
} from "./planby/CustomTimeline";
import type {
	Area,
	ChannelWithOmittedUuid,
	FetchZone,
	Mode,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	durationInDays,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import { useCallback, useState } from "react";
import {
	EXPANDED_SIZE,
	COMPACT_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import SwitchButton from "@metronome/components/Switch";
import { ErrorBoundary } from "@sentry/react";
import { add, addDays, differenceInCalendarDays, sub } from "date-fns";
import type { ChannelWithPosition } from "@nessprim/planby-pro/dist/Epg/helpers/types";
import { CustomItem } from "./planby/CustomItem";
import { Button } from "@metronome/components/ui/button";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	CaretLeftIcon,
	CaretRightIcon,
	MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import { useRect } from "@metronome/hooks/useRect";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@metronome/components/ui/tooltip";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { Label } from "@metronome/components/ui/label";
type PrevNextItems = {
	nextScrollRight: number;
	nextScrollLeft: number;
	itemsOnTheRight: number;
	itemOnTheRightTitle: string;
	itemsOnTheLeft: number;
	itemOnTheLeftTitle: string;
};
type ProgramByChannel = {
	uuid: string | undefined;
	items: Array<{ left: number; width: number; title: string; since: string }>;
}[];
function usePrevNextItems(
	programByChannel: ProgramByChannel,
	channelUuid: string,
	scrollX: number,
	timelineWidth: number,
): PrevNextItems {
	const itemsInChannel =
		programByChannel.find((p) => p.uuid === channelUuid)?.items ?? [];

	const nextRightIndex = itemsInChannel.findIndex(
		(v) => v.left > scrollX + timelineWidth,
	);
	const nextLeftIndex = itemsInChannel.findIndex(
		(v) => v.left + v.width < scrollX,
	);
	const nextScrollRight =
		nextRightIndex >= 0
			? itemsInChannel[nextRightIndex]?.left -
				timelineWidth +
				itemsInChannel[nextRightIndex]?.width
			: -1;
	const nextScrollLeft =
		nextLeftIndex >= 0 ? itemsInChannel[nextLeftIndex]?.left : -1;

	const itemsOnTheRight =
		nextRightIndex >= 0 ? itemsInChannel.length - nextRightIndex : 0;
	const itemsOnTheLeft =
		nextLeftIndex >= 0 ? itemsInChannel.length - nextLeftIndex : 0;

	const itemOnTheRightTitle = itemsInChannel[nextRightIndex]?.title;
	const itemOnTheLeftTitle = itemsInChannel[nextLeftIndex]?.title;

	return {
		nextScrollRight,
		nextScrollLeft,
		itemsOnTheRight,
		itemsOnTheLeft,
		itemOnTheRightTitle,
		itemOnTheLeftTitle,
	};
}

type SelectRangeProps = {
	rangeMode: Mode["type"];
	availableRanges: Array<Mode["type"]>;
	setRangeMode: (type: Mode["type"]) => void;
};
const SelectRange = ({
	rangeMode,
	setRangeMode,
	availableRanges,
}: SelectRangeProps) => {
	return (
		<Select
			value={rangeMode as string}
			onValueChange={(value: Mode["type"]) => setRangeMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select range" />
			</SelectTrigger>
			<SelectContent>
				{availableRanges.map((range) => (
					<SelectItem key={range} value={range}>
						<FormattedMessage id={range.toUpperCase()} />
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

type SelectDisplayModeProps = {
	displayMode: "compact" | "expanded";
	setDisplayMode: (display: SelectDisplayModeProps["displayMode"]) => void;
};
const SelectDisplayMode: React.FC<SelectDisplayModeProps> = ({
	displayMode,
	setDisplayMode,
}) => {
	return (
		<Select
			value={displayMode}
			onValueChange={(value: "compact" | "expanded") => setDisplayMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select mode" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="compact">
					<FormattedMessage id="COMPACT" />
				</SelectItem>
				<SelectItem value="expanded">
					<FormattedMessage id="EXPANDED" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type SelectOverlapModeProps = {
	overlapMode: "stack" | "layer" | "none";
	setOverlapMode: (display: SelectOverlapModeProps["overlapMode"]) => void;
};

const SelectOverlapMode: React.FC<SelectOverlapModeProps> = ({
	overlapMode,
	setOverlapMode,
}) => {
	return (
		<Select
			value={overlapMode}
			onValueChange={(value: SelectOverlapModeProps["overlapMode"]) =>
				setOverlapMode(value)
			}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select overlap" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="none">
					<FormattedMessage id="NONE" />
				</SelectItem>
				<SelectItem value="stack">
					<FormattedMessage id="STACK" />
				</SelectItem>
				<SelectItem value="layer">
					<FormattedMessage id="LAYER" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type LoadMoreDaysFn = (
	timeDirection: "past" | "future",
	range: Mode["type"],
) => void;

type TimeRangeNavigationProps = {
	range: Mode["type"];
	plannedStart: string;
	plannedEnd: string;
	onLoadMoreDays: LoadMoreDaysFn;
};
const TimeRangeNavigation: React.FC<TimeRangeNavigationProps> = ({
	range,
	plannedStart,
	plannedEnd,
	onLoadMoreDays,
}) => {
	return (
		<div className="mx-auto flex items-center gap-2">
			<Button
				variant="ghost"
				size="icon-sm"
				onClick={() => onLoadMoreDays("past", range)}
			>
				<ArrowLeftIcon />
			</Button>
			<FormattedDateTimeRange
				from={new Date(plannedStart)}
				to={new Date(plannedEnd)}
			/>
			<Button
				variant="ghost"
				size="icon-sm"
				onClick={() => onLoadMoreDays("future", range)}
			>
				<ArrowRightIcon />
			</Button>
		</div>
	);
};

function getEnoughDaysToFillSelectedMode(range: Mode["type"], days: number) {
	if (range === "week") return Math.abs(days) < 7 ? 7 - Math.abs(days) : 0;
	if (range === "month") return Math.abs(days) < 30 ? 30 - Math.abs(days) : 0;
	if (range === "day") return Math.abs(days) < 1 ? 1 - Math.abs(days) : 0;
	return 0;
}

function getInitialPlannedEnd(start: string, end: string): string {
	const range = getDefaultTimeRange(start, end);
	const days = differenceInCalendarDays(start, end);
	const daysToAdd = getEnoughDaysToFillSelectedMode(range, days);
	return addDays(end, daysToAdd).toISOString();
}

type StepsTimelineProps = {
	initialPlannedStart: string;
	initialPlannedEnd: string;
	epg: Program[];
	channels: ChannelWithOmittedUuid[];
	areas?: Area[];
	CustomChannelItem?: React.ComponentType<{
		channel: ChannelWithPosition;
		children: React.ReactNode;
		displayMode: "compact" | "expanded";
	}>;
	selectedMetadataDefs?: string[];
	fetchZone?: FetchZone;
};

export const StepsTimeline: React.FC<StepsTimelineProps> = ({
	initialPlannedStart,
	initialPlannedEnd,
	epg,
	areas,
	channels = [],
	CustomChannelItem,
	fetchZone,
}) => {
	const [planRect, planRef] = useRect();

	// state
	const [plannedStart, setPlannedStart] = useState(initialPlannedStart);
	const [plannedEnd, setPlannedEnd] = useState(
		getInitialPlannedEnd(initialPlannedStart, initialPlannedEnd),
	);
	const [displayMode, setDisplayMode] =
		useState<SelectDisplayModeProps["displayMode"]>("compact");

	const [overlapMode, setOverlapMode] =
		useState<SelectOverlapModeProps["overlapMode"]>("none");
	const [dayWidthMultiplier, setDayWidthMultiplier] = useState([1]);
	const [showSchedule, setShowSchedule] = useState(false);

	// getters
	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const startDate = getStartDate(plannedStart);

	const [rangeMode, setRangeMode] = useState<Mode["type"]>(defaultRange);

	const onRangeModeChange = useCallback(
		(type: Mode["type"]) => {
			const days = differenceInCalendarDays(plannedStart, plannedEnd);
			const daysToAdd = getEnoughDaysToFillSelectedMode(type, days);
			if (daysToAdd > 0) {
				setPlannedEnd((prev) => addDays(prev, daysToAdd).toISOString());
			}

			setRangeMode(type);
		},
		[plannedEnd, plannedStart],
	);

	const endDate = rangeMode === "day" ? plannedEnd : endOfDay(plannedEnd);
	const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

	const onFetchZone = useCallback((zone: Zone) => {
		// todo
	}, []);

	const dayWidth = getDayWidth(rangeMode, numberOfDays) * dayWidthMultiplier[0];
	const sidebarWidth = 180;
	// planBy hooks
	const { getEpgProps, getLayoutProps, scrollX, onScrollRight, onScrollLeft } =
		useEpg({
			epg,
			areas,
			channels,
			startDate,
			endDate: endDateFormatted,
			mode: { type: rangeMode, style: "modern" },
			sidebarWidth,
			dayWidth,
			itemHeight: displayMode === "expanded" ? EXPANDED_SIZE : COMPACT_SIZE,
			isSidebar: !!CustomChannelItem,
			isTimeline: true,
			isLine: true,
			isCurrentTime: true,
			theme: planbyLightTheme,
			overlap: {
				enabled: overlapMode !== "none",
				mode: overlapMode !== "none" ? overlapMode : "stack",
			},
			globalStyles,
			fetchZone: fetchZone
				? fetchZone
				: {
						enabled: false,
						timeSlots: 1,
						onFetchZone,
					},
		});

	const onLoadMoreDays: LoadMoreDaysFn = useCallback(
		(timeDirection, mode) => {
			if (timeDirection === "past") {
				const nextPlannedStart = sub(plannedStart, { [`${mode}s`]: 1 });
				setPlannedStart(nextPlannedStart.toISOString());
			} else if (timeDirection === "future") {
				const nextPlannedStart = add(plannedEnd, { [`${mode}s`]: 1 });
				setPlannedEnd(nextPlannedStart.toISOString());
			}
		},
		[plannedEnd, plannedStart],
	);

	if (!(new Date(plannedStart) <= new Date(plannedEnd))) {
		return <SchedulerError startDateAfterEndDate />;
	}

	const { programs } = getLayoutProps();

	const programByChannel = channels.map((c) => ({
		uuid: c.uuid,
		items: [
			...new Map(
				programs
					.filter((p) => p.data.channelUuid === c.uuid)
					.map((p) => [
						p.position.left,
						{
							left: p.position.left,
							width: p.position.width,
							title: p.data.title,
							since: p.data.since,
						},
					]),
			).values(),
		],
	}));

	const timelineWidth = planRect?.width ? planRect.width - sidebarWidth : 0;

	return (
		<>
			<div className="flex gap-2 items-center p-2">

				<Popover>
					<PopoverTrigger asChild>
						<Button variant="secondary">
							<MixerHorizontalIcon />
							<FormattedMessage id="SETTINGS" />
						</Button>
					</PopoverTrigger>
					<PopoverContent align="start">
						<div className="my-2">
							<h4 className="text-lg font-medium leading-none">
								<FormattedMessage id="TIMELINE_SETTINGS" />
							</h4>
							<p className="text-sm text-muted-foreground">
								<FormattedMessage id="TIMELINE_DESC" />
							</p>
						</div>
						<div className="flex flex-col gap-2">
							<div className="grid grid-cols-3 items-center gap-4">
								<Label htmlFor="range">
									<FormattedMessage id="RANGE" />
								</Label>
								<SelectRange
									rangeMode={rangeMode}
									setRangeMode={onRangeModeChange}
									availableRanges={["day", "week", "month"]}
								/>
							</div>
							<div className="grid grid-cols-3 items-center gap-4">
								<Label htmlFor="disposition">
									<FormattedMessage id="DISPLAY" />
								</Label>
								<SelectOverlapMode
									overlapMode={overlapMode}
									setOverlapMode={setOverlapMode}
								/>
							</div>
							<div className="grid grid-cols-3 items-center gap-4">
								<Label htmlFor="width">
									<FormattedMessage id="HEIGHT" />
								</Label>
								<SelectDisplayMode
									displayMode={displayMode}
									setDisplayMode={setDisplayMode}
								/>
							</div>
						</div>
					</PopoverContent>
				</Popover>

				<SwitchButton
					checked={showSchedule}
					onCheckedChange={setShowSchedule}
				/>
				<span>
					<FormattedMessage id="SHOW_SCHEDULE" />
				</span>

				<TimeRangeNavigation
					plannedStart={plannedStart}
					plannedEnd={plannedEnd}
					onLoadMoreDays={onLoadMoreDays}
					range={rangeMode}
				/>

				<Slider
					className="w-44 ms-auto py-2"
					onValueChange={setDayWidthMultiplier}
					value={dayWidthMultiplier}
					min={1}
					max={10}
					step={1}
				/>
			</div>
			<ErrorBoundary fallback={<SchedulerError />}>
				<div ref={planRef}>
					<Epg {...getEpgProps()}>
						<TooltipProvider delayDuration={0}>
							<Layout
								{...getLayoutProps()}
								renderProgram={({ program, hourWidth, ...rest }) => {
									return (
										<CustomItem
											key={program.data.id}
											program={program}
											hourWidth={hourWidth}
											showStepType={false}
											showSchedule={showSchedule}
											{...rest}
										/>
									);
								}}
								{...(CustomChannelItem
									? {
											renderChannel: ({ channel, ...rest }) => {
												const {
													nextScrollRight,
													itemsOnTheRight,
													nextScrollLeft,
													itemsOnTheLeft,
													itemOnTheRightTitle,
													itemOnTheLeftTitle,
												} = usePrevNextItems(
													programByChannel,
													channel.uuid,
													scrollX,
													timelineWidth,
												);

												const top = channel.position.top;
												return (
													<CustomChannelItem
														key={channel.uuid}
														channel={channel}
														displayMode={displayMode}
														{...rest}
													>
														{!!itemsOnTheRight && (
															<Tooltip>
																<TooltipTrigger asChild>
																	<Button
																		onClick={() =>
																			onScrollRight(nextScrollRight - scrollX)
																		}
																		style={{
																			top: `${top}px`,
																			right: `-${timelineWidth - 10}px`,
																			transform: "translateY(calc(20px - 50%))",
																		}}
																		variant="outline"
																		className="absolute"
																		size="icon-sm"
																	>
																		<CaretRightIcon />
																	</Button>
																</TooltipTrigger>
																<TooltipContent align="end">
																	{itemOnTheRightTitle}
																</TooltipContent>
															</Tooltip>
														)}
														{!!itemsOnTheLeft && (
															<Tooltip>
																<TooltipTrigger asChild>
																	<Button
																		onClick={() =>
																			onScrollLeft(scrollX - nextScrollLeft)
																		}
																		style={{
																			top: `${top}px`,
																			left: `${sidebarWidth}px`,
																			transform: "translateY(calc(20px - 50%))",
																		}}
																		className={"absolute"}
																		variant="outline"
																		size="icon-sm"
																	>
																		<CaretLeftIcon />
																	</Button>
																</TooltipTrigger>
																<TooltipContent align="start">
																	{itemOnTheLeftTitle}
																</TooltipContent>
															</Tooltip>
														)}
													</CustomChannelItem>
												);
											},
										}
									: {})}
								renderTimeline={
									rangeMode === "day"
										? (props) => (
												<TimelineWithNav {...props} timelineDividers={2} />
											)
										: (props) => <WeekMonthTimelineWithNav {...props} />
								}
							/>
						</TooltipProvider>
					</Epg>
				</div>
			</ErrorBoundary>
		</>
	);
};
