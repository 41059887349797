import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Input } from "./ui/input";

type Props = {
	value?: string;
	type?: string;
	onValidate?: (value: string) => void;
};

export const EditableCell: React.FC<Props> = ({
	value = "",
	type = "text",
	onValidate = () => null,
}): JSX.Element => {
	const initialValue = useRef(value);
	const [newValue, setNewValue] = useState<string>(value);

	useEffect(() => {
		setNewValue(value);
	}, [value]);

	const onBlur = (): void => {
		if (initialValue.current === newValue) {
			return;
		}
		onValidate(newValue);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setNewValue(event.target.value);
		if (type === "date") {
			onValidate(event.target.value);
		}
	};

	return (
		<Input
			type={type}
			value={newValue}
			onChange={handleChange}
			className="hover:bg-white"
			onKeyPress={(e) => {
				if (e.key === "Enter") {
					e.currentTarget.blur();
				}
			}}
			onBlur={onBlur}
		/>
	);
};

EditableCell.displayName = "EditableCell";

export default EditableCell;
