import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { BaseParams } from "@metronome/api/useAttachments";
import { useCreateLinkAttachment } from "@metronome/api/useAttachments";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import FloatingInput from "@metronome/components/FloatingInput";
import type React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import styles from "./sendLinkAttachment.module.scss";
import { Button } from "@metronome/components/ui/button";
import { DialogTitle } from "@metronome/components/ui/dialog";
import { AttachmentTypeAPI } from "@metronome/types/Attachment";

type SendLinkAttachmentProps = {
	context: BaseParams["context"];
	contextId: string;
	required?: boolean;
	specId?: string;
	onSuccess: () => void;
	children?: React.ReactNode;
};

export const SendLinkAttachment: React.FC<SendLinkAttachmentProps> = ({
	context,
	contextId,
	required,
	specId,
	onSuccess,
}) => {
	const intl = useIntl();

	const { mutateAsync: createAttachment, isPending } = useCreateLinkAttachment(
		context,
		contextId,
		onSuccess,
	);

	const handleSubmit: React.ChangeEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const data = {} as Record<string, string>;

		for (const [key, value] of formData.entries()) {
			if (typeof value === "string") {
				data[key] = value;
			}
		}

		createAttachment({
			type: AttachmentTypeAPI.enum.Link,
			link: data.link,
			name: data.name,
			description: data.description ?? "",
			specId: required ? specId : undefined,
		});
	};

	return (
		<form className="flex flex-col gap-2 p-4" onSubmit={handleSubmit}>
			<FloatingInput required label="link" name="link" />
			<FloatingInput required label="name" name="name" />
			<FloatingInput required={false} label="description" name="description" />
			<input
				type="submit"
				disabled={isPending}
				className={styles.submit}
				value={
					isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({ id: "SUBMIT" })
				}
			/>
		</form>
	);
};

export const SendLinkAttachmentAsDialog: React.FC<SendLinkAttachmentProps> = ({
	context,
	contextId,
	children,
	required = false,
	specId,
	onSuccess,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button>
					{children || <FontAwesomeIcon fixedWidth icon={["fas", "link"]} />}
				</Button>
			</DialogTrigger>
			<DialogContent size="small">
				<DialogTitle>Send Link</DialogTitle>
				<SendLinkAttachment
					context={context}
					contextId={contextId}
					specId={specId}
					required={required}
					onSuccess={() => {
						setOpen(false);
						onSuccess?.();
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
