import type React from "react";
import {
	type FC,
	createContext,
	useContext,
	useEffect,
	useState,
	useMemo,
} from "react";
import { useParams } from "@tanstack/react-router";
import useWorkspaces from "@metronome/api/useWorkspaces";

interface PageDataContextModel {
	pageTitle?: string[];
	setPageTitle: (_title: string[]) => void;
}

const PageDataContext = createContext<PageDataContextModel | null>({
	setPageTitle: (_title: string[]) => _title,
});

type PageDataProviderProps = {
	children: React.ReactNode;
};

const PageDataProvider: React.FC<PageDataProviderProps> = ({ children }) => {
	const [pageTitle, setPageTitle] = useState<string[]>([""]);
	const value: PageDataContextModel = useMemo(
		() => ({
			pageTitle,
			setPageTitle,
		}),
		[pageTitle],
	);
	return (
		<PageDataContext.Provider value={value}>
			{children}
		</PageDataContext.Provider>
	);
};

const usePageData = (): PageDataContextModel => {
	const context = useContext(PageDataContext);
	if (!context) {
		throw new Error("usePageData must be used within a provider");
	}
	return context;
};

type Props = {
	organizationName?: string;
	children: React.ReactNode;
};

const PageTitle: FC<Props> = ({ children, organizationName }) => {
	const { data: workspaces } = useWorkspaces();
	const { workspaceId } = useParams({
		strict: false,
	});
	const workspaceName =
		workspaces?.find((ws) => ws.id === workspaceId)?.name ?? "";

	const { setPageTitle } = usePageData();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (children) {
			let title = [] as string[];

			if (workspaceName && organizationName) {
				title = [workspaceName, children.toString()];
				setPageTitle(title);
			} else if (workspaceName) {
				title = [workspaceName, children.toString()];
				setPageTitle(title);
			} else {
				title = [children.toString()];
				setPageTitle(title);
			}
			document.title = title.join(" > ");
		}
		return () => {
			document.title = workspaceName;
			setPageTitle([workspaceName]);
		};
	}, [children, organizationName, workspaceName]);

	return <></>;
};

export { PageTitle, PageDataProvider, usePageData };
