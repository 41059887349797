import { useStages } from '@metronome/api/useProcessInstance'
import LoadingMetronome from '@metronome/components/LoadingMetronome'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import * as DataGrid from '@metronome/components/DataGrid'
import { TableContainer } from '@metronome/components/TableContainer/TableContainer'
import { ErrorBoundary } from '@sentry/react'
import type { IStepInstanceLight } from '@metronome/types/StepInstance'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import { StepInstanceIcon } from '@metronome/components/IconStepInstance'
import { Lozenge } from '@metronome/components/Lozenge'
import { UpdateResolution } from '@metronome/features/UpdateResolution'
import { getIsResolutionDoneOrCancel } from '@metronome/utils/resolution'
import { useTranslatedStatusResolution } from '@metronome/types/Resolution'
import Flag from '@metronome/components/Flag'
import { formatDurationToString } from '@metronome/utils/durationHelpers'
import { StepSchedule } from '@metronome/features/StepSchedule'
import { isSameDay } from 'date-fns'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import { calcTillDate } from '@metronome/utils/planby'
import { CustomItemSchedule } from '@metronome/features/UpdateStepInstanceSchedule'
import { fuzzyFilter } from '@metronome/utils/tableHelper'
import { isWithinRange } from '@metronome/utils/isWithinRange'
import { Button } from '@metronome/components/ui/button'

const columnHelpers = createColumnHelper<IStepInstanceLight>()

const StepsTable = () => {
  const { processId } = Route.useParams()
  const { data: gates, isLoading, isFetched } = useStages(processId)
  const translatedStatusResolution = useTranslatedStatusResolution()

  const data = useMemo(
    () =>
      gates?.flatMap((g) =>
        g.steps.flatMap((s) => s.stepInstances.map((i) => i)),
      ),
    [gates],
  )

  const columns = useMemo(
    () => [
      columnHelpers.accessor('type', {
        id: 'type',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludes',
        header: () => <FormattedMessage id="TYPE" />,
        cell: (info) => (
          <div className="flex items-center justify-center">
            <StepInstanceIcon type={info.getValue()} />
          </div>
        ),
      }),
      columnHelpers.accessor('businessDimension.name', {
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="NAME" />,
        cell: (info) => <span>{info.getValue() as string}</span>,
      }),
      columnHelpers.accessor((row) => row.businessDimension.labels ?? [], {
        id: 'LABELS',
        filterFn: 'arrIncludes',
        enableSorting: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="LABELS" />,
        cell: (info) => (
          <div className="flex gap-2">
            {info
              .getValue()
              ?.sort((a, b) => a.localeCompare(b))
              .map((label: string) => (
                <Lozenge key={label} appearance="default">
                  {label}
                </Lozenge>
              ))}
          </div>
        ),
      }),
      columnHelpers.accessor('schedule', {
        id: 'scheduleLowerBandToUpperBand',
        enableColumnFilter: false,
        enableSorting: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="SCHEDULE_BAND" />,
        cell: (info) => {
          const upper = info.row.original.schedule.scheduleUpperBand
          const sameDay = isSameDay(upper, info.getValue().scheduleLowerBand)
          return (
            <div className="flex gap-2 items-center">
              <span>
                {!sameDay && (
                  <>
                    <FormattedDate
                      value={info.getValue().scheduleLowerBand}
                      month="short"
                      day="2-digit"
                      year="2-digit"
                    />
                    {' - '}
                  </>
                )}
                <FormattedTime value={info.getValue().scheduleLowerBand} />
              </span>
              <ArrowRightIcon className="inline" />
              <span>
                {!sameDay && (
                  <>
                    <FormattedDate
                      value={info.getValue().scheduleUpperBand}
                      month="short"
                      day="2-digit"
                      year="2-digit"
                    />
                    {' - '}
                  </>
                )}
                <FormattedTime value={info.getValue().scheduleUpperBand} />
              </span>
            </div>
          )
        },
      }),
      columnHelpers.accessor('schedule', {
        id: 'planningAt',
        enableColumnFilter: false,
        enableSorting: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="PLANNED_AT" />,
        cell: (info) => {
          return (
            <CustomItemSchedule
              stepId={info.row.original.id}
              since={
                info.getValue().plannedAt ?? info.getValue().scheduleLowerBand
              }
              till={calcTillDate(info.getValue())}
              schedule={info.getValue()}
              processId={processId}
              childrenAsChild={Boolean(!info.getValue().plannedAt)}
            >
              {!info.getValue().plannedAt ? (
                <Button variant="secondary">
                  <FormattedMessage id="SET_PLANNED_AT" />
                </Button>
              ) : undefined}
            </CustomItemSchedule>
          )
        },
      }),
      columnHelpers.accessor('schedule', {
        id: 'duration',
        enableColumnFilter: false,
        enableSorting: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="DURATION" />,
        cell: (info) => (
          <span>
            {formatDurationToString(info.getValue().estimatedDuration) ||
              formatDurationToString(info.getValue()?.plannedDuration)}
          </span>
        ),
      }),
      columnHelpers.accessor('schedule', {
        id: 'SCHEDULE',
        enableColumnFilter: false,
        enableSorting: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="SCHEDULE" />,
        cell: (info) => <StepSchedule schedule={info.getValue()} />,
      }),
      columnHelpers.accessor('resolution', {
        id: 'resolution',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludes',
        meta: {
          modelRefArray: translatedStatusResolution,
        },
        header: () => <FormattedMessage id="STATE" />,
        cell: (info) => {
          if (
            getIsResolutionDoneOrCancel(info.getValue())
            // !isProcessActive(info.row.original.processInstance.state)
          ) {
            return (
              <Lozenge appearance="default">
                <FormattedMessage
                  id={`RESOLUTION.${info.row.original.resolution}`}
                />
              </Lozenge>
            )
          }
          return (
            <UpdateResolution
              useSyncUpdate
              key={info.row.original.id}
              stepInstance={info.row.original}
              processId={processId}
            />
          )
        },
      }),
      columnHelpers.accessor('isFlagged', {
        enableColumnFilter: false,
        footer: (props) => props.column.id,
        header: () => <FormattedMessage id="FLAG" />,
        cell: (info) => (
          <Flag
            isFlagged={!!info.getValue()}
            id={info.row.original.id}
            context="step-instances"
          />
        ),
      }),
    ],
    [translatedStatusResolution, processId],
  )

  const instance = useReactTable<IStepInstanceLight>({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getExpandedRowModel: getExpandedRowModel(),
    // getSubRows: (row) => row,
    enableColumnFilters: true,
    filterFns: {
      fuzzy: fuzzyFilter,
      isWithinRange,
    },
  })

  if (isLoading) {
    return <LoadingMetronome />
  }
  return (
    <div className="w-full px-1">
      <DataGrid.TableHeader<IStepInstanceLight>
        isLoading={isLoading}
        instance={instance}
      />

      <div className="flex flex-row overflow-auto gap-2 w-full min-h-[35vh] min-w-48">
        <TableContainer>
          <div className={'flex flex-col grow overflow-auto'}>
            <ErrorBoundary>
              <DataGrid.TableBody<IStepInstanceLight>
                instance={instance}
                isFetching={isLoading}
                isFetched={isFetched}
              />
            </ErrorBoundary>
          </div>
        </TableContainer>
      </div>
    </div>
  )
}

const StepsSearchParams = z.object({
  search: z.string().optional(),
  stepId: z.string().optional(), // this optional step id is used for modal view
  page: z.number().optional().catch(1),
  pageSize: z.number().optional().catch(25),
  sortOrder: z.union([z.literal('asc'), z.literal('desc')]).optional(),
  sortBy: z.string().optional(),
  startFrom: z.string().optional(),
  startTo: z.string().optional(),
  resolution: z.array(z.string()).optional(),
  timeliness: z.string().optional(),
  responsibleIds: z.array(z.string()).optional(),
})

export const Route = createFileRoute(
  '/$workspaceId/stream/$streamId/process/$processId/steps',
)({
  validateSearch: (searchParams) => StepsSearchParams.parse(searchParams),
  component: () => <StepsTable />,
})
