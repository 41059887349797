import { z } from "zod";

export const AttachmentTypeAPI = z.enum(["Document", "Link"]);
export type AttachmentTypeAPI = z.infer<typeof AttachmentTypeAPI>;

/*
  File
*/
export const AttachmentFileModel = z.object({
	name: z.string(),
	description: z.string(),
	id: z.string(),
	type: z.literal(AttachmentTypeAPI.Enum.Document),
	file: z.instanceof(File),
	previewFile: z.instanceof(File).optional(),
	specId: z.string().optional(),
});
export type AttachmentFileModel = z.infer<typeof AttachmentFileModel>;

export const UnsavedAttachmentFile = AttachmentFileModel.omit({ id: true });
export type UnsavedAttachmentFile = z.infer<typeof UnsavedAttachmentFile>;

/*
  Link
*/
export const AttachmentLinkModel = z.object({
	id: z.string(),
	link: z.string(),
	type: z.literal(AttachmentTypeAPI.Enum.Link),
	name: z.string(),
	specId: z.string().optional(),
	description: z.string(),
});

export type AttachmentLinkModel = z.infer<typeof AttachmentLinkModel>;

export const UnsavedAttachmentLink = AttachmentLinkModel.omit({ id: true });
export type UnsavedAttachmentLink = z.infer<typeof UnsavedAttachmentLink>;

/*
  Interface 
*/
export const AttachmentAPI = z.object({
	content: z.string(),
	previewContent: z.string().optional(),
	id: z.string(),
	type: AttachmentTypeAPI,
	uploadedBy: z.string(),
	uploadedAt: z.number().optional(),
	description: z.string(),
	name: z.string(),
});
export type AttachmentAPI = z.infer<typeof AttachmentAPI>;

export const AttachmentRequirementAPI = z.object({
	id: z.string(),
	description: z.string(),
	name: z.string(),
	type: AttachmentTypeAPI,
	attachment: AttachmentAPI,
});
export type AttachmentRequirementAPI = z.infer<typeof AttachmentRequirementAPI>;

/**
 * Pending attachments
 */

export const PendingAttachmentRequirementAPI = AttachmentRequirementAPI.omit({
	attachment: true,
});
export type PendingAttachmentRequirementAPI = z.infer<
	typeof PendingAttachmentRequirementAPI
>;

/**
 * Step Instance Attachments
 */

export const StepInstanceAttachments = z.object({
	required: z.array(AttachmentRequirementAPI),
	preloaded: z.array(AttachmentAPI),
	additional: z.array(AttachmentAPI),
});
export type StepInstanceAttachments = z.infer<typeof StepInstanceAttachments>;
