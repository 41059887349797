import React, {
	type HTMLAttributes,
	type ChangeEventHandler,
	type FocusEventHandler,
} from "react";
import styles from "./floatingInput.module.scss";

type Props = {
	label: string;
	required: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	defaultValue?: HTMLAttributes<"input">["defaultValue"];
	name?: string;
};

export const FloatingInput = React.forwardRef<HTMLInputElement, Props>(
	(
		{ label, required, onChange, onBlur, name, ...props },
		forwardRef,
	): JSX.Element => (
		<div className="relative">
			<input
				ref={forwardRef}
				placeholder={label}
				className={styles.input}
				required={required}
				onChange={onChange}
				onBlur={onBlur}
				name={name}
				{...props}
			/>
			<label htmlFor={name} className={styles.label}>
				{label}
				{required ? "*" : ""}
			</label>
		</div>
	),
);

FloatingInput.displayName = "FloatingInput";

export default FloatingInput;
